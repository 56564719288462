$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let language = $("body").attr("lang");
  const menuProducts = [];

  $(document).on("change", "#is_volume", function () {
    if ($(this).prop("checked")) {
      $("#volume").prop("required", true);
      $("#volume").prop("type", "number");
    } else {
      $("#volume").removeAttr("required");
      $("#volume").prop("type", "hidden");
    }
  });

  if (p == "organizer_event_ticket_category" && a == "update") {
    let mva = $("#vat_code").children("option:selected").text();
    let degreeOfCoverage = formattedValueForNumberInput(
      $("#degree_of_coverage").val()
    );
    let coverageOfContribution = formattedValueForNumberInput(
      $("#coverage_contribution").val()
    );
    let productPrice = formattedValueForNumberInput(
      $("#ticket_price_ex_vat").val()
    );
    let priceExMva = formattedValueForNumberInput(
      $("#price-ex-mva-value").val()
    );
    let priceIncMva = formattedValueForNumberInput(
      $("#price-inc-mva-value").val()
    );

    let event_ticket_category_update_ajax_lock = false;

    $(document).on("blur", "#product-sku-input", function (e) {
      e.preventDefault();
      const encodedData = $("#existing_product_number").attr(
        "data-product-number-list"
      );
      const existingNumberList = JSON.parse(atob(encodedData));
      const newValue = $(this).val();
      const oldValue = $(this).attr("data-old-value");
      const filteredexistingNumbers = existingNumberList.filter(
        (number) => number !== oldValue
      );

      if (filteredexistingNumbers.includes(newValue)) {
        alert("varenummer finnes allerede!");
        $(this).val(oldValue);
        $(this).focus();
        return;
      }
    });

    $(document).on("change", "#ticket_price_ex_vat", function (e) {
      e.preventDefault();
      productPrice = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "ticket_price_ex_vat",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#degree_of_coverage", function (e) {
      e.preventDefault();

      degreeOfCoverage = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "degree_of_coverage",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#coverage_contribution", function (e) {
      e.preventDefault();
      coverageOfContribution = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "coverage_contribution",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#price-ex-mva-value", function (e) {
      e.preventDefault();
      priceExMva = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "price_ex_mva_value",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#price-inc-mva-value", function (e) {
      e.preventDefault();
      priceIncMva = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "price_inc_mva_value",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#vat_code", function (e) {
      e.preventDefault();
      mva = $(this).children("option:selected").text();
      const payload = {
        field: "price_ex_mva_value",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $("#menu-product-table-body")
      .children()
      .each(function () {
        const productId = $(this).find(".amount-input").data("product-id"); // Extract product ID from the ID attribute
        const amount = $(this).find(".amount-input").val(); // Get the value of amount input
        // Create an object with product_id and amount and push it to menuProducts array
        menuProducts.push({ product_id: productId, amount: amount });
      });

    $(document).on("submit", "#choose-product-search-form", function (e) {
      e.preventDefault();
      let search_string = $("#choose_product_search_string").val();
      let location_id = $("body").attr("event_id");
      let product_type = 0;

      getProductList(location_id, search_string, product_type);
    });

    $(document).on("click", ".menu-product-item", function () {
      const productName = $(this).data("menu-product-name");
      const productId = $(this).attr("id");
      const productVolume = $(this).data("menu-product-volume");
      const tableBody = $("#menu-product-table-body");
      const menuProduct = {
        product_id: productId,
        name: productName,
        amount: 1,
      };

      const newRow = `
        <tr id="menu-product-${productId}">
            <td class="name-column">${productName}</td>
            <td>
                <div class="input-group">
                    <input type="number" data-product-id="${productId}" class="form-control amount-input" value="1" min="0" step="any"
                        onfocus="this.select()">
                    <div class="input-group-append">
                        <span class="input-group-text">${productVolume}</span>
                    </div>
                </div>
            </td>
            <td class="text-center">
                <button type="button" data-menu-product-id="${productId}" class="btn btn-danger btn-sm remove-menu-product">${
        language === "no" ? "Fjerne" : "Remove"
      }</button>
            </td>
        </tr>
    `;
      tableBody.append(newRow);

      menuProducts.push(menuProduct);

      $("#choose-product-modal").modal("hide");
    });

    $(document).on(
      "input",
      "#product-discount-price-input, #product-discount-percent-input, #discount-percent-input",
      function () {
        if (this.value.length > 1 && this.value.indexOf(0) == 0) {
          $(this).val(Math.abs(this.value));
        }
      }
    );

    $(document).on(
      "keydown",
      "#product-discount-price-input, #discount-percent-input, #product-discount-percent-input",
      function (event) {
        if (event.key === "-") {
          event.preventDefault();
        }
      }
    );

    $(document).on("change", ".amount-input", function () {
      const value = parseFloat(this.value) || 0;
      const productId = $(this).data("product-id");

      const index = menuProducts.findIndex(
        (product) => product.product_id == productId
      );

      if (index !== -1) {
        menuProducts[index].amount = value;
      }
    });

    $(document).on("click", ".remove-menu-product", function (e) {
      e.preventDefault();
      const productId = $(this).data("menu-product-id");
      $(this).closest("tr").remove();

      const index = menuProducts.findIndex(
        (product) => product.product_id == productId
      );

      if (index !== -1) {
        menuProducts.splice(index, 1);
      }
    });

    $(document).on("change", "#is_menu_product", function () {
      if ($(this).is(":checked")) {
        $("#choose-product-button").show();
      } else {
        $("#choose-product-button").hide();
      }
    });

    if (
      $("#event_ticket_category_id").val() !== undefined &&
      $("#event_ticket_category_id").val() != null &&
      $("#event_ticket_category_id").val() !== ""
    ) {
      $(document).on("submit", "#event_ticket_category_form", function () {
        if (!event_ticket_category_update_ajax_lock) {
          let event_id = $("#event_id").val();
          let product_type = parseInt($("#product_type").val());

          event_ticket_category_update_ajax_lock = true;

          $(":disabled").each(function () {
            $(this).removeAttr("disabled");
          });

          let formData = new FormData(
            document.getElementById("event_ticket_category_form")
          );
          let productCategoryPOSIds = formData.getAll("product-category-pos");
          let productCategorySelfServiceIds = formData.getAll(
            "product-category-self-service"
          );
          if (productCategoryPOSIds.length > 0) {
            formData.append("product_category_pos_list", productCategoryPOSIds);
          }
          if (productCategorySelfServiceIds.length > 0) {
            formData.append(
              "product_category_self_service_list",
              productCategorySelfServiceIds
            );
          }

          if (menuProducts.length > 0) {
            formData.append("menu_products", JSON.stringify(menuProducts));
          }

          $.ajax({
            type: "POST",
            url: "/ajax/organizer_event_ticket_category/update",
            data: formData,
            enctype: "multipart/form-data",
            async: true,
            cache: false,
            processData: false,
            contentType: false,
          })
            .done(function (data) {
              if (data.message !== undefined && data.message !== null) {
                prependAlert(
                  "#div_event_ticket_category_button",
                  data.message,
                  "success",
                  null,
                  "#event_ticket_category_form"
                );
                $(".card-box-to-hide").hide();
                $("#event_ticket_category_button").hide();

                const params = new URLSearchParams(window.location.search);
                const page = params.get("page");

                setTimeout(function () {
                  if (product_type === 0) {
                    document.location.href =
                      "/location/" +
                      event_id +
                      "/product" +
                      (page ? "?page=" + page : "");
                  } else {
                    document.location.href =
                      "/location/" +
                      event_id +
                      "/product/extra" +
                      (page ? "?page=" + page : "");
                  }
                }, 2000);
              }
            })
            .fail(function (data) {
              if (
                data.responseJSON.redirect_url !== undefined &&
                data.responseJSON.redirect_url !== null
              ) {
                document.location.href = data.responseJSON.redirect_url;
              }

              if (
                data.responseJSON.error_message !== undefined &&
                data.responseJSON.error_message !== null
              ) {
                prependAlert(
                  "#div_event_ticket_category_button",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#event_ticket_category_form"
                );
              }

              event_ticket_category_update_ajax_lock = false;
            })
            .always(function () {
              event_ticket_category_update_ajax_lock = false;
            });
        }

        return false;
      });
    }

    function getProductList(location_id, search_string, product_type) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/menu_product_search",
        data: {
          location_id,
          search_string,
          product_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#choose-product-list-container").empty();
            $("#choose-product-list-container").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function updateInputValues(data) {
      if (
        data.productPrice !== undefined &&
        data.productPrice !== null &&
        data.priceExMva !== undefined &&
        data.priceExMva !== null &&
        data.priceIncMva !== undefined &&
        data.priceIncMva !== null &&
        data.degreeOfCoverage !== undefined &&
        data.degreeOfCoverage !== null &&
        data.coverageOfContribution !== undefined &&
        data.coverageOfContribution !== null &&
        data.mva !== undefined &&
        data.mva !== null
      ) {
        priceExMva = data.priceExMva;
        priceIncMva = data.priceIncMva;
        degreeOfCoverage = data.degreeOfCoverage;
        coverageOfContribution = data.coverageOfContribution;
        mva = data.mva;
        productPrice = data.productPrice;
        $("#coverage_contribution").val(
          data.coverageOfContribution.toString().replace(".", ",")
        );
        $("#price-ex-mva-value").val(data.priceExMva.toString().replace(".", ","));
        $("#price-inc-mva-value").val(data.priceIncMva.toString().replace(".", ","));
        $("#ticket_price_ex_vat").val(data.productPrice.toString().replace(".", ","));
        $("#degree_of_coverage").val(data.degreeOfCoverage.toString().replace(".", ","));
      }
    }
  }
});
