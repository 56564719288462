$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery =
    $("body").attr("start_date")?.replace("T", " ") || null;
  let endDateFromQuery = $("body").attr("end_date")?.replace("T", " ") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;

  if (p === "statistic_product_group") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );

    var deviceWidth = $(window).width();
    let customerId = 0;
    let orderBy = "name";
    let orderByDirection = "desc";
    let isSorting = false;
    let isMobile = false;
    let isTablet = false;
    let isDesktop = false;
    var deviceWidth = $(window).width();

    if (deviceWidth < 768) {
      isMobile = true;
    } else if (deviceWidth >= 768 && deviceWidth < 992) {
      isTablet = true;
    } else {
      isDesktop = true;
    }

    if (filterBy) {
      let periodTitleOfFilterBy = "";
      prevSelectedPeriod = prevSelectedPeriodQuery
        ? prevSelectedPeriodQuery
        : prevSelectedPeriod;
      if (filterBy === "week") {
        $("#period-week").prop("checked", true);
        period = "week";
        prevSelectedPeriod = "week";
        customPeriod = customPeriodQuery ? customPeriodQuery : "day";
        startDate = startDateQuery
          ? startDateQuery
          : moment().startOf("week").format("YYYY-MM-DD") + " 00:00";
        endDate = endDateQuery
          ? endDateQuery
          : moment().endOf("week").format("YYYY-MM-DD") + " 23:59";
        periodTitleOfFilterBy = "Uke nr" + " " + moment(startDate).isoWeek();
      } else if (filterBy === "day") {
        $("#period-day").prop("checked", true);
        customPeriod = customPeriodQuery ? customPeriodQuery : "time";
        startDate = startDateQuery
          ? startDateQuery
          : moment().startOf("week").format("YYYY-MM-DD") + " 00:00";
        endDate = endDateQuery
          ? endDateQuery
          : moment().endOf("week").format("YYYY-MM-DD") + " 23:59";
        $(`#custom-period option[value="time"]`).prop("selected", true);

        periodTitleOfFilterBy = dayFormatAbbreviation(
          moment.utc(startDate).format("ddd - DD.MM.YYYY")
        );
      } else if (filterBy === "month") {
        $("#period-month").prop("checked", true);
        period = "month";
        prevSelectedPeriod = "month";
        startDate = startDateQuery
          ? startDateQuery
          : moment().startOf("week").format("YYYY-MM-DD") + " 00:00";
        endDate = endDateQuery
          ? endDateQuery
          : moment().endOf("week").format("YYYY-MM-DD") + " 23:59";
        if (isDesktop) {
          customPeriod = "day";
          $(`#custom-period option[value="time"]`).prop("selected", false);
          $(`#custom-period option[value="day"]`).prop("selected", true);
        } else {
          customPeriod = "week";
          $(`#custom-period option[value="day"]`).prop("selected", false);
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $(`#custom-period option[value="day"]`).prop("disabled", true);
        }
        periodTitleOfFilterBy = moment(startDate).format("MMMM");
      } else {
        period = "custom";
        customPeriod = customPeriodQuery ? customPeriodQuery : customPeriod;
        prevSelectedPeriod = prevSelectedPeriodQuery
          ? prevSelectedPeriodQuery
          : prevSelectedPeriod;
        startDate = startDateQuery;
        endDate = endDateQuery;
        $("#period-custom").prop("checked", true);
        $(`#custom-period option[value=${customPeriod}]`).prop(
          "selected",
          true
        );
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        if (prevSelectedPeriod === "day") {
          periodTitleOfFilterBy = dayFormatAbbreviation(
            moment.utc(startDate).format("ddd - DD.MM.YYYY")
          );
        } else if (prevSelectedPeriod === "week") {
          periodTitleOfFilterBy = "Uke nr" + " " + moment(startDate).isoWeek();
        } else if (prevSelectedPeriod === "month") {
          periodTitleOfFilterBy = moment(startDate).format("MMMM");
        } else {
          if (startDate === endDate) {
            $("#custom-period").prop("disabled", true);
          } else {
            $("#custom-period option[value='time']").addClass("disabledOption");
            $("#custom-period option[value='time']").prop("disabled", true);
          }

          periodTitleOfFilterBy =
            moment(startDate).format("DD-MM-YYYY") +
            " : " +
            moment(endDate).format("DD-MM-YYYY");
        }
      }
      setTimeout(() => $("#period-title").text(periodTitleOfFilterBy), 50);
    } else {
      $("#period-day").prop("checked", true);
    }

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      isSorting = true;
      orderBy = $(this).attr("data-order-by");
      orderByDirection = $(this).attr("data-order-by-direction");
      productGroupUniqueId = $(this)
        .closest("tr")
        .attr("data-product-group-id");
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });
    $(document).on("change", "#custom-period", function (e) {
      e.preventDefault();
      customPeriod = $(this).val();

      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#top-product-group-id", function (e) {
      e.preventDefault();
      $("#product-group-id").val(0);
      $("select[name=product-group-id]").select2({
        theme: "bootstrap4",
      });
      topProductGroupUniqueId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#supplier-id", function (e) {
      e.preventDefault();
      supplierId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#product-group-id", function (e) {
      e.preventDefault();
      $("#top-product-group-id").val(0);
      $("select[name=top-product-group-id]").select2({
        theme: "bootstrap4",
      });
      productGroupUniqueId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });
    $(document).on("change", "#user-id", function (e) {
      e.preventDefault();
      userId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#product-id", function (e) {
      e.preventDefault();
      productId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#platform", function (e) {
      e.preventDefault();
      platform = $(this).val();

      if (platform === "self-service") {
        userId = 0;
        $("#user-id").val(userId);
        $("#user-id").prop("disabled", true);
      } else {
        $("#user-id").prop("disabled", false);
      }
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });
    $(document).on("change", 'input[type="radio"]', function (e) {
      period = $('input[type="radio"]:checked').val();
      prevSelectedPeriod = period !== "custom" ? period : prevSelectedPeriod;
      if (period === "day") {
        startDate = moment().format("YYYY-MM-DD") + " 00:00";
        endDate = moment().format("YYYY-MM-DD") + " 23:59";
        const currentDayFormatted = dayFormatAbbreviation(
          moment().format("ddd - DD.MM.YYYY")
        );
        customPeriod = "time";
        $("#period-title").text(currentDayFormatted);
      } else if (period === "week") {
        startDate = moment().startOf("week").format("YYYY-MM-DD") + " 00:00";
        endDate = moment().endOf("week").format("YYYY-MM-DD") + " 23:59";
        const currentWeekNumber = moment().utc().isoWeek();
        customPeriod = "day";
        $(`#custom-period option[value="day"]`).prop("selected", true);
        $("#period-title").text("Uke nr" + " " + currentWeekNumber);
      } else if (period === "month") {
        startDate = moment().startOf("month").format("YYYY-MM-DD") + " 00:00";
        endDate = moment().endOf("month").format("YYYY-MM-DD") + " 23:59";
        durationOfstartAndEndDate = 30;
        displayGraphDynamically(durationOfstartAndEndDate);
        if (isDesktop) {
          customPeriod = "day";
          $(`#custom-period option[value="time"]`).prop("selected", false);
          $(`#custom-period option[value="day"]`).prop("selected", true);
        } else {
          customPeriod = "week";
          $(`#custom-period option[value="day"]`).prop("selected", false);
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $(`#custom-period option[value="day"]`).prop("disabled", true);
        }
        const currentMonth = moment().format("MMMM");
        $("#period-title").text(currentMonth);
      } else {
        $("#period-title").text("Custom");
        compareBy = null;
        $("#compare").val("0").trigger("change.select2");
        if (startDate == endDate) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period").prop("disabled", false);
        }
      }
      customPeriod = $("#custom-period").val();
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      toggleCustomDatePicker();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    function getDateAndTimeChange(callback) {
      $("#start-date").datetimepicker({
        dateFormat: "yy-mm-dd",
        timeFormat: "HH:mm",
        showButtonPanel: true,
        regional: ["no", "NO"],
        onClose: function () {
          if (startDate !== $(this).val()) {
            let choosenDate = $("#start-date").val();

            if (dateAndPeriodValidation(choosenDate, endDate)) {
              startDate = choosenDate;
              isDateChanged = true;
              period = $('input[name="period"]:checked').val();
              if (startDate == endDate) {
                $("#custom-period option[value='time']").prop("selected", true);
                $("#custom-period").prop("disabled", true);
              } else {
                durationOfstartAndEndDate = moment
                  .utc(endDate)
                  .diff(moment(startDate), "days");
                displayGraphDynamically(durationOfstartAndEndDate);
                $("#custom-period").prop("disabled", false);
              }
              getOrderedProductQuantity(
                startDate,
                endDate,
                period,
                supplierId,
                productGroupUniqueId,
                locationId,
                userId,
                platform,
                orderBy,
                orderByDirection,
                customPeriod,
                topProductGroupUniqueId,
                compareBy,
                productId
              );
              prevSelectedPeriod = "custom";
              $("#period-title").text(
                moment(startDate).format("DD-MM-YYYY") +
                  " : " +
                  moment(endDate).format("DD-MM-YYYY")
              );
            }
          }
        },
      });

      // Initialize the Datepicker for end-date
      $("#end-date").datetimepicker({
        dateFormat: "yy-mm-dd",
        timeFormat: "HH:mm",
        showButtonPanel: true,
        onClose: function () {
          if (endDate !== $(this).val()) {
            let currentendDate = $("#end-date").val();
            if (dateAndPeriodValidation(startDate, currentendDate)) {
              isDateChanged = true;
              endDate = currentendDate;
              period = $('input[name="period"]:checked').val();
              if (startDate.split(" ")[0] == endDate) {
                $("#custom-period option[value='time']").prop("selected", true);
                $("#custom-period").prop("disabled", true);
              } else {
                durationOfstartAndEndDate = moment
                  .utc(endDate)
                  .diff(moment(startDate.split(" ")[0]), "days");
                displayGraphDynamically(durationOfstartAndEndDate);

                $("#custom-period").prop("disabled", false);
              }
              getOrderedProductQuantity(
                startDate,
                endDate,
                period,
                supplierId,
                productGroupUniqueId,
                locationId,
                userId,
                platform,
                orderBy,
                orderByDirection,
                customPeriod,
                topProductGroupUniqueId,
                compareBy,
                productId
              );
              prevSelectedPeriod = "custom";
              $("#period-title").text(
                moment(startDate).format("DD-MM-YYYY") +
                  " : " +
                  moment(endDate).format("DD-MM-YYYY")
              );
            }
          }
        },
      });
    }

    $(document).on("click", "#previous-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "day").format("YYYY-MM-DD") +
          " 00:00";
        endDate =
          moment.utc(endDate).subtract(1, "day").format("YYYY-MM-DD") +
          " 23:59";
        periodTitle = dayFormatAbbreviation(
          moment(startDate).format("ddd - DD.MM.YYYY")
        );
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate = moment
          .utc(startDate)
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        endDate = moment(endDate).subtract(1, "week").format("YYYY-MM-DD");
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate = moment
          .utc(startDate)
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        endDate = moment(endDate).subtract(1, "month").format("YYYY-MM-DD");
        periodTitle = moment(startDate).format("MMMM");
      }
      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        endDate = moment(startDate).subtract(1, "day").format("YYYY-MM-DD");

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        startDate = moment
          .utc(endDate)
          .subtract(durationOfstartAndEndDate, "days")
          .format("YYYY-MM-DD");
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("click", "#next-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).add(1, "day").format("YYYY-MM-DD") + " 00:00";
        endDate =
          moment.utc(endDate).add(1, "day").format("YYYY-MM-DD") + " 23:59";
        periodTitle = dayFormatAbbreviation(
          moment(startDate).format("ddd - DD.MM.YYYY")
        );
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate = moment(startDate).add(1, "week").format("YYYY-MM-DD");
        endDate = moment(endDate).add(1, "week").format("YYYY-MM-DD");
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate = moment(startDate).add(1, "month").format("YYYY-MM-DD");
        endDate = moment(endDate).add(1, "month").format("YYYY-MM-DD");
        periodTitle = moment(startDate).format("MMMM");
      }

      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        startDate = moment(endDate).add(1, "day").format("YYYY-MM-DD");

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        endDate = moment
          .utc(startDate)
          .add(durationOfstartAndEndDate, "days")
          .format("YYYY-MM-DD");
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    function eventLineChart(
      categoryArray,
      seriesData1,
      seriesData2,
      seriesData3,
      interval = null
    ) {
      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: {
          type: "column",
        },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 4, // Adjust line width as needed
          },
        },

        xAxis: {
          categories: categoryArray,
          labels: {
            formatter: function () {
              if (startDate.split(" ")[0] === endDate.split(" ")[0]) {
                return this.value;
              } else if (customPeriod === "week") {
                return "Uke " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat(
                  "%B",
                  new Date(this.value).getTime()
                );
              } else {
                return Highcharts.dateFormat(
                  "%e/%b",
                  new Date(this.value).getTime()
                );
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          title: {
            text: "Antall varer",
          },
        },
        series:
          seriesData2.length === 0 && seriesData3.length === 0
            ? [
                {
                  name: "Antall varer",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ]
            : [
                {
                  name: "Antall varer",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Antall varer forrige uke",
                  data: seriesData2.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Antall varer forrige år",
                  data: seriesData3.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ],
        tooltip: {
          formatter: function () {
            var tooltipText;
            if (startDate.split(" ")[0] === endDate.split(" ")[0]) {
              // Default formatting for datetime xAxis
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", this.x) +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                this.x +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", this.x) +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", this.x) +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }
    $(document).on("change", "#compare", function (e) {
      e.preventDefault();
      compareBy = $(this).val();

      if (compareBy === "day-compare") {
        period = "day";
        $("#period-day").prop("checked", true);
      } else if (compareBy === "week-compare") {
        period = "week";
        $("#period-week").prop("checked", true);
      } else if (compareBy === "month-compare") {
        period = "month";
        $("#period-month").prop("checked", true);
      } else {
        compareBy = null;
      }
      $('input[type="radio"]:checked').trigger("change");
    });

    function getOrderedProductQuantity(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      orderBy,
      orderByDirection,
      customPeriod,
      topProductGroupUniqueId = 0,
      compareBy = null,
      productId = 0
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_product_group/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          orderBy,
          orderByDirection,
          customPeriod,
          topProductGroupUniqueId,
          compareBy,
          productId,
        },
      })
        .done(function (data) {
          if (data) {
            if (data.totalQuantity) {
              $("#key-figure-text").text(data.totalQuantity);
            } else {
              $("#key-figure-text").text("0");
            }

            if (data.html && !isSorting) {
              $("#product-list-table").empty;
              $("#product-list-table").html(data.html);
            }
            if (data.html && isSorting) {
              $(`#product_table_${productGroupUniqueId}`).empty;
              $(`#product_table_${productGroupUniqueId}`).html(data.html);
            }
            if (
              data.xAxisArray.length > 0 &&
              data.seriesData1 &&
              data.seriesData2 &&
              data.seriesData3
            ) {
              eventLineChart(
                data.xAxisArray,
                data.seriesData1,
                data.seriesData2,
                data.seriesData3
              );
            }
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getHourGraph(data) {
      let hourArray = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];

      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let xAxisArray = [];
      $.map(hourArray, function (hour) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === hour.toString();
        });
        let totalQuantity = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalQuantity += parseInt(ele.total_quantity);
          });
        }

        if (compareBy && compareBy != 0) {
          let foundedLastWeek = $.grep(data.lastWeekList, function (item) {
            return item.order_date === hour.toString();
          });
          let foundedLastYear = $.grep(data.lastYearList, function (item) {
            return item.order_date === hour.toString();
          });

          let totalTurnOver2 = 0;
          let totalTurnOver3 = 0;

          if (foundedLastWeek.length > 0) {
            $.map(foundedLastWeek, function (item) {
              totalTurnOver2 += parseInt(item.total_quantity);
            });
          }
          if (foundedLastYear.length > 0) {
            $.map(foundedLastYear, function (item) {
              totalTurnOver3 += parseInt(item.total_quantity);
            });
          }
          seriesData2.push(totalTurnOver2);
          seriesData3.push(totalTurnOver3);
        }
        seriesData1.push(totalQuantity);
        xAxisArray.push(
          (hour.toString().length === 1
            ? "0" + hour.toString()
            : hour.toString()) + ":00"
        );
      });
      let interval = 3600 * 1000;
      eventLineChart(
        xAxisArray,
        seriesData1,
        seriesData2,
        seriesData3,
        interval
      );
    }

    function getDayGraph(data) {
      let dailyTotals = {};
      let dailyTotals2 = {};
      let dailyTotals3 = {};
      data.currentList.forEach(function (item) {
        if (Array.isArray(item) && item.length === 0) {
          // Skip empty arrays
          return;
        }
        let date = item.order_date.split(" ")[0];
        if (dailyTotals[date]) {
          dailyTotals[date] += parseInt(item.total_quantity);
        } else {
          dailyTotals[date] = parseInt(item.total_quantity);
        }
      });

      // Prepare data for Highcharts
      let dateArray = [];
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let startedDate = new Date(startDate.split(" ")[0]);

      if (compareBy && compareBy !== "0") {
        data.lastWeekList.forEach(function (item) {
          let lastWeekDate = item.order_date.split(" ")[0];

          if (dailyTotals2[lastWeekDate]) {
            dailyTotals2[lastWeekDate] += parseInt(item.total_quantity);
          } else {
            dailyTotals2[lastWeekDate] = parseInt(item.total_quantity);
          }
        });
        data.lastYearList.forEach(function (item) {
          let lastYearDate = item.order_date.split(" ")[0];
          if (dailyTotals3[lastYearDate]) {
            dailyTotals3[lastYearDate] += parseInt(item.total_quantity);
          } else {
            dailyTotals3[lastYearDate] = parseInt(item.total_quantity);
          }
        });
      }

      let endedDate = new Date(endDate.split(" ")[0]);
      endedDate.setHours(23, 59, 59, 0);

      while (startedDate <= endedDate) {
        let dateStr = startedDate.toISOString().split(" ")[0]; // Convert to YYYY-MM-DD format
        let total1 = dailyTotals[dateStr] || 0; // Use total from dailyTotals or 0 if not available

        dateArray.push(dateStr);
        seriesData1.push(total1);

        startedDate.setDate(startedDate.getDate() + 1); // Move to the next day
      }

      if (compareBy) {
        const newStartDate = moment(startDate.split(" ")[0]);
        const newEndDate = moment(endDate.split(" ")[0]);

        // Get the same date for the previous week, month, and year
        let lastWeekStartDate = newStartDate
          .clone()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        let lastMonthStartDate = newStartDate
          .clone()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        let lastYearStartDate = newStartDate
          .clone()
          .subtract(1, "year")
          .format("YYYY-MM-DD");

        let lastYearEndDate = newEndDate
          .clone()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        let lastWeekEndDate = newEndDate
          .clone()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        let lastMonthEndDate = newEndDate
          .clone()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        if (
          (period === "week" || period === "month") &&
          customPeriod === "day"
        ) {
          while (lastWeekStartDate <= lastWeekEndDate) {
            let total2 = dailyTotals2[lastWeekStartDate] || 0; // Use total from dailyTotals or 0 if not available
            seriesData2.push(total2);

            lastWeekStartDate = moment(lastWeekStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }

          while (lastYearStartDate <= lastYearEndDate) {
            let total3 = dailyTotals3[lastYearStartDate] || 0; // Use total from dailyTotals or 0 if not available

            seriesData3.push(total3);

            lastYearStartDate = moment(lastYearStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
        }
      }

      let interval = 24 * 3600 * 1000;
      eventLineChart(
        dateArray,
        seriesData1,
        seriesData2,
        seriesData3,
        interval
      );
    }

    function getWeekGraph(data) {
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let weekNumbers = [];

      while (startDay.isBefore(endDay)) {
        let weekNumber = startDay.isoWeek();
        weekNumbers.push(weekNumber);
        startDay.add(1, "day");
      }
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      $.map(weekNumbers, function (week_number) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === week_number.toString();
        });

        let totalWeeklyTurnover1 = 0;
        let totalWeeklyTurnover2 = 0;
        let totalWeeklyTurnover3 = 0;
        if (found.length > 0) {
          $.map(found, function (item) {
            totalWeeklyTurnover1 += parseInt(item.total_quantity);
          });
        }
        seriesData1.push(totalWeeklyTurnover1);

        if (compareBy) {
          let found2 = $.grep(data.lastWeekList, function (item) {
            return item.order_date === week_number.toString();
          });
          if (found2.length > 0) {
            $.map(found2, function (item) {
              totalWeeklyTurnover2 += parseInt(item.total_quantity);
            });
            seriesData2.push(totalWeeklyTurnover1);
          }
          let found3 = $.grep(data.lastYearList, function (item) {
            return item.order_date === week_number.toString();
          });
          if (found3.length > 0) {
            $.map(found3, function (item) {
              totalWeeklyTurnover3 += parseInt(item.total_quantity);
            });
            seriesData3.push(totalWeeklyTurnover3);
          }
        }
      });

      eventLineChart(weekNumbers, seriesData1, seriesData2, seriesData3);
    }

    function getMonthGraph(data) {
      data.sort(
        (a, b) => new Date(a.order_confirmed) - new Date(b.order_confirmed)
      );
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let monthNumbers = [];

      while (startDay.isBefore(endDay)) {
        let monthNumber = startDay.month() + 1;
        monthNumbers.push(monthNumber);
        startDay.add(1, "month");
      }
      let seriesData = [];
      $.map(monthNumbers, function (month_number) {
        let found = $.grep(data, function (item) {
          return item.order_date === month_number.toString();
        });
        let monthNumberToDate = moment()
          .startOf("year")
          .add(month_number - 1, "month")
          .format("YYYY-MM-DD");
        let totalQuantity = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalQuantity += parseInt(ele.total_quantity);
          });
        }

        let formatedDate = new Date(monthNumberToDate);
        let timestamp = formatedDate.getTime();
        let dataSet = [timestamp, totalQuantity];
        seriesData.push(dataSet);
      });
      eventLineChart(seriesData);
    }

    getOrderedProductQuantity(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      orderBy,
      orderByDirection,
      customPeriod,
      topProductGroupUniqueId,
      compareBy,
      productId
    );
    toggleCustomDatePicker();
    getDateAndTimeChange();
  }
});
