let locationCart = {};
let getCartModal;
let updateCartSummary;
let updateMenuButtons;
let emptyCartOnModalClose = true;
let emptyAllCart;
let orderStorageIds;
let tableId = $("body").attr("table_id") || null;

var mediaRecorder;
var audioChunks = [];
var isRecording = false;
var audioStream;

function setLocationCart(data) {
  if (data.location_id !== undefined && data.location_id !== null) {
    locationCart.location_id = data.location_id;
    if (data.location_type !== undefined && data.location_type !== null) {
      locationCart.location_type = data.location_type;
    }
    if (data.table_number !== undefined && data.table_number !== null) {
      locationCart.table_number = data.table_number;
    }
    if (
      data.number_of_products !== undefined &&
      data.number_of_products !== null
    ) {
      locationCart.number_of_products = data.number_of_products;
    }
    if (data.amount !== undefined && data.amount !== null) {
      locationCart.amount = data.amount;
    }
    if (data.tips_percent !== undefined && data.tips_percent !== null) {
      locationCart.tips_percent = data.tips_percent;
    }
    if (data.payment_method !== undefined && data.payment_method !== null) {
      locationCart.payment_method = data.payment_method;
    }
    if (data.comment !== undefined && data.comment !== null) {
      locationCart.comment = data.comment;
    }
    if (data.name !== undefined && data.name !== null) {
      locationCart.name = data.name;
    }
    if (data.phone_number !== undefined && data.phone_number !== null) {
      locationCart.phone_number = data.phone_number;
    }
    if (data.pickup_date !== undefined && data.pickup_date !== null) {
      locationCart.pickup_date = data.pickup_date;
    }
    if (data.pickup_time !== undefined && data.pickup_time !== null) {
      locationCart.pickup_time = data.pickup_time;
    }
    if (data.promo_code !== undefined && data.promo_code !== null) {
      locationCart.promo_code = data.promo_code;
    }
    if (
      data.pick_up_at_the_bar !== undefined &&
      data.pick_up_at_the_bar !== null
    ) {
      locationCart.pick_up_at_the_bar = data.pick_up_at_the_bar;
    }
    if (data.products !== undefined && data.products !== null) {
      for (let i = 0; i < data.products.length; i++) {
        let productObj = data.products[i];
        let productExtras = [];
        for (let j = 0; j < productObj.product_extra.length; j++) {
          let productExtraObj = productObj.product_extra[j];
          let productExtra = {
            product_extra_id: productExtraObj.product_extra_id,
            amount: productExtraObj.amount,
            discount_percent: productExtraObj.discount_percent,
          };
          productExtras.push(productExtra);
        }
        let product = {
          product_id: productObj.product_id,
          amount: productObj.amount,
          comment: productObj.comment,
          discount_percent: productObj.discount_percent,
          original_product_price: productObj.original_product_price,
        };
        if (productExtras.length) {
          product.product_extra = productExtras;
        }
        locationCart.products.push(product);
      }
    }

    if (
      data.order_storage_ids !== undefined &&
      data.order_storage_ids !== null
    ) {
      orderStorageIds = data.order_storage_ids;
    }
    updateCartSummary();
    updateMenuButtons();
    if (
      data.set_payment_failed !== undefined &&
      data.set_payment_failed !== null &&
      data.set_payment_failed === true
    ) {
      locationCart.payment_failed = true;
    }
    locationCart.order_storage_identifier = "";

    if (data.checkout_type !== undefined && data.checkout_type !== null) {
      locationCart.checkout_type = data.checkout_type;
    }
    if (
      data.table_customer_id !== undefined &&
      data.table_customer_id !== null
    ) {
      locationCart.table_customer_id = data.table_customer_id;
    }
    if (
      data.amount_without_discount !== undefined &&
      data.amount_without_discount !== null
    ) {
      locationCart.amount_without_discount = data.amount_without_discount;
    }
    getCartModal();

    if (
      data.set_payment_failed !== undefined &&
      data.set_payment_failed !== null &&
      data.set_payment_failed === true
    ) {
      delete locationCart.payment_failed;
    }
  }
}

$(document).ready(function () {
  let p = $("body").attr("p");
  let shop_type = $("body").attr("shop_type") ?? null;
  let posType = $("#location-products").data("pos-type");
  let locationId = $("#location").attr("data-location-id");
  let locationType = $("#location-products").attr("data-location-type");
  let userId = $("#location-products").attr("data-user-id");
  const organizerId = $("body").attr("organizer_id");
  const error_message = $("body").attr("error_message") ?? null;
  const splitOrderId = $("body").attr("order") ?? null;
  const canSaveOnTable =
    $("#location-cart-summary").attr("data-save-on-table") == 1 ? true : false;

  getCartModal = function (autoPayWithTerminal) {
    for (let [index, product] of locationCart.products.entries()) {
      if (product.amount === 0) {
        locationCart.products.splice(index, 1);
      }
    }

    locationCart.amount_without_discount = floor(
      locationCart.products.reduce(
        (total, product) =>
          total +
          parseFloat(
            (product.amount * product.original_product_price).toFixed(2)
          ),
        0
      )
    );

    $(document).on("keypress", ".take-away-input", function (e) {
      if (e.which === 13) {
        e.preventDefault();
      }
    });

    $(document).on("click", ".close-terminals", function () {
      $("#terminal-list-model").modal("hide");
      return false;
    });

    let data = {
      location_id: locationId,
      location_type: locationCart.location_type,
      table_number: locationCart.table_number,
      products: locationCart.products,
      tips_percent: locationCart.tips_percent,
      payment_method: locationCart.payment_method,
      comment: locationCart.comment,
      promo_code: locationCart.promo_code,
      amount_without_discount: locationCart.amount_without_discount ?? 0,
      shop_type: shop_type === "dine-in" ? p : shop_type,
      customer_id: locationCart.customer_id || "",
      checkout_type: locationCart.checkout_type ?? null,
      organizer_id: organizerId,
      order_storage_ids: orderStorageIds,
      can_save_on_table: canSaveOnTable,
      shop_type,
    };

    if (locationCart.payment_failed === true) {
      data.payment_failed = locationCart.payment_failed;
    }

    if (locationCart.name != "") {
      data.name = locationCart.name;
    }

    if ($.isNumeric(locationCart.phone_number)) {
      data.phone_number = locationCart.phone_number;
    }

    if (locationCart.pick_up_at_the_bar == true) {
      data.pick_up_at_the_bar = locationCart.pick_up_at_the_bar;
    }
    if (orderStorageIds !== null) {
      data.order_storage_ids = orderStorageIds;
    }

    $.ajax({
      type: "POST",
      url: "/ajax/order/get_cart",
      data: data,
    })
      .done(function (data) {
        if (data.html !== undefined && data.html !== null) {
          if ($("#product-extra-modal").length) {
            $("#product-extra-modal").remove();
          }
          if ($("#cart-modal").length) {
            $("#cart-modal").remove();
          }
          $("#event-public-list-organizer").after(data.html);
          locationCart.products = data.cart_products;
          const totalAmount = parseFloat(
            String(
              $("#cart-amount-with-tips").data("cart-amount-with-tips") || "0"
            ).replace(",", ".")
          );
          locationCart.amount = totalAmount;
          locationCart.amount_without_discount = floor(
            locationCart.products.reduce(
              (total, product) =>
                total +
                parseFloat(product.amount * product.original_product_price),
              0
            )
          );

          updateCartSummary();

          if (locationCart.order_discount_percent) {
            $("#discount-percent-input").val(
              locationCart.order_discount_percent
            );
          }

          if (
            locationCart.order_storage_identifier != null &&
            locationCart.order_storage_identifier != ""
          ) {
            locationCart.payment_method = "none";
          } else if (
            autoPayWithTerminal !== undefined &&
            autoPayWithTerminal !== null &&
            autoPayWithTerminal === true
          ) {
            locationCart.payment_method = "payment_terminal";
          } else {
            if (
              $("#split-payment-cart-modal").length &&
              shop_type != "dine-in"
            ) {
              $("#split-payment-cart-modal").modal("show");
            } else {
              $("#cart-modal").modal("show");
            }
          }
        }

        $("#customer_select").select2({
          theme: "bootstrap4",
        });

        if (locationCart.table_number !== "") {
          $(".order-button").each(function () {
            if (
              $(this).is("[data-toggle]") &&
              ($(this).data("payment-method") === "nets_card" ||
                $(this).data("payment-method") === "nets_vipps")
            ) {
              $(this).prop("disabled", true);
            } else {
              $(this).prop("disabled", false);
            }
          });
          $(".save_order_self_service").prop("disabled", false);
        }

        if (localStorage.getItem("saved_table_id") && shop_type == "dine-in") {
          setTableForSelfService(localStorage.getItem("saved_table_id"));
          $(".order-button").each(function () {
            if (
              $(this).is("[data-toggle]") &&
              ($(this).data("payment-method") === "nets_card" ||
                $(this).data("payment-method") === "nets_vipps")
            ) {
              $(this).prop("disabled", true);
            } else {
              $(this).prop("disabled", false);
            }
          });
          $(".save_order_self_service").prop("disabled", false);
        }

        if (tableId) {
          locationCart.table_number = tableId;
          $("#self-service-table-select-btn").text(tableId);
          $("#self-service-table-select-btn").prop("disabled", true);
          $(".save_order_self_service").attr("data-identifier", tableId);
          $(".order-button").each(function () {
            if (
              $(this).is("[data-toggle]") &&
              ($(this).data("payment-method") === "nets_card" ||
                $(this).data("payment-method") === "nets_vipps")
            ) {
              $(this).prop("disabled", true);
            } else {
              $(this).prop("disabled", false);
            }
          });
          $(".save_order_self_service").prop("disabled", false);
        }

        const allpersonlist = $(".select-person");
        if (allpersonlist.length > 0) {
          const lastPerson = allpersonlist[allpersonlist.length - 1];
          if (lastPerson) {
            lastPerson.click();
          }
        }

        emptyCartOnModalClose = true;
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
        cartQuickPaymentTerminalLock = false;
      });
  };

  function setTableForSelfService(tableNumber) {
    let selectedTableNumber = tableNumber;
    locationCart.table_number = selectedTableNumber;
    $("#self-service-table-select-btn").text(selectedTableNumber);
    $("#self-service-table-select-btn").prop("disabled", true);
    $(".save_order_self_service").attr("data-identifier", selectedTableNumber);
    $(".save_order_self_service").attr("data-extra-table", "");
    $("#view-saved-table-contanier").removeClass("hidden");
    $("#view-saved-table-contanier").attr("data-table-id", selectedTableNumber);
    $("#location-cart-summary").addClass("has-saved-table");
    if ($(".space-2").hasClass("d-none")) {
      $(".space-2").removeClass("d-none");
    }
    if (!$(".space-2").hasClass("d-block")) {
      $(".space-2").addClass("d-block");
    }
  }

  if ((p === "events" && posType === "scroll") || p === "events_self_service") {
    locationCart = {
      location_id: locationId,
      location_type: locationType,
      table_number: "",
      number_of_products: 0,
      amount: 0,
      tips_percent: 0,
      products: [],
      payment_method: "",
      comment: "",
      name: "",
      phone_number: "",
      pickup_date: "",
      pickup_time: "",
      promo_code: "",
      pick_up_at_the_bar: false,
      order_storage_identifier: "",
      user_id: null,
      customer_id: "",
      take_away_address: "",
      first_name: "",
      last_name: "",
    };

    function getCartInfoFromSession() {
      $.ajax({
        type: "GET",
        url: "/ajax/order/get_cart_from_session",
      })
        .done(function (data) {
          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationId
          ) {
            data.set_payment_failed = true;
            setLocationCart(data);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    if (!($(".clear-shopping-cart").length > 0)) {
      getCartInfoFromSession();
    }

    updateMenuButtons = function () {
      if (locationCart.products.length) {
        for (let i = 0; i < locationCart.products.length; i++) {
          let productId = locationCart.products[i].product_id;
          let input = $("#product-id-" + productId);
          input.val(locationCart.products[i].amount);
          let addToCartButton = input.siblings("button.add-product");
          if (
            input.val() > 0 &&
            !(
              addToCartButton.attr("data-has-product-extra") == 1 &&
              addToCartButton.attr("data-force-product-extra-popup") == 1
            )
          ) {
            if (!input.siblings("span").hasClass("d-none")) {
              input.siblings("span").addClass("d-none");
            }
            if (input.siblings(".remove-product").hasClass("d-none")) {
              input.siblings(".remove-product").removeClass("d-none");
            }
            if (input.hasClass("d-none")) {
              input.removeClass("d-none");
            }
          }
        }
      }
    };

    function areEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
          return false;
        }
      }
      return true;
    }

    $(document).on("click", "#add-to-cart-button", function () {
      let numberOfProduct = parseInt($("input[name='product-to-order']").val());
      let productId = parseInt(
        $("input[name='product-to-order']").attr("data-product-id")
      );
      let productWithCategoryId = $("input[name='product-to-order']").attr(
        "data-product-with-category-id"
      );
      let productType = $(this).attr("data-product-type");
      let comment = $("textarea[name='comment']").val();
      let productExtraArray = $("input.product-extra-to-order");
      let productExtraTextArray = $("input.product-extra-text-to-order");
      let productExtras = [];

      productExtraArray.each(function () {
        if (parseInt($(this).val()) > 0) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: parseInt($(this).val()),
            original_price: parseFloat($(this).attr("data-price-incl-vat")),
          };
          productExtras.push(productExtra);
        }
      });

      productExtraTextArray.each(function () {
        if ($(this).is(":checked")) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: 1,
            checked: true,
            original_price: 0,
          };
          productExtras.push(productExtra);
        }
      });

      productExtras.sort(function (a, b) {
        return a["product_extra_id"] > b["product_extra_id"]
          ? 1
          : a["product_extra_id"] < b["product_extra_id"]
          ? -1
          : 0;
      });
      let allProducts = locationCart.products;

      let productInCart = false;
      if (productExtras.length > 0) {
        if (allProducts.length > 0) {
          for (let [index, product] of allProducts.entries()) {
            if (
              product.product_id === productId &&
              (productWithCategoryId !== "" &&
              productWithCategoryId !== undefined
                ? product.product_with_category_id === productWithCategoryId
                : true)
            ) {
              if ("product_extra" in product) {
                if (areEqual(product.product_extra, productExtras) === true) {
                  locationCart.products[index].amount =
                    locationCart.products[index].amount + numberOfProduct;
                  locationCart.products[index].comment = comment;
                  productInCart = true;
                  break;
                }
              }
            }
          }
        }
      } else {
        if (allProducts.length > 0) {
          for (let product of allProducts) {
            if (
              product.product_id === productId &&
              (productWithCategoryId !== "" &&
              productWithCategoryId !== undefined
                ? product.product_with_category_id === productWithCategoryId
                : true)
            ) {
              if (!("product_extra" in product)) {
                let productIndex = locationCart.products.findIndex(
                  (obj) => obj.product_id === product.product_id
                );
                if (productIndex !== -1) {
                  locationCart.products[productIndex].amount =
                    locationCart.products[productIndex].amount +
                    numberOfProduct;
                  locationCart.products[productIndex].comment = comment;
                  productInCart = true;
                  break;
                }
              }
            }
          }
        }
      }

      if (productInCart === false) {
        const productPrice =
          parseFloat($("#subtotal").text()) / numberOfProduct;
        let product = {
          product_id: productId,
          amount: numberOfProduct,
          comment: comment,
          discount_percent: locationCart.order_discount_percent || 0,
          original_product_price: productPrice,
          product_with_category_id: productWithCategoryId,
        };
        if (productExtras.length > 0) {
          product.product_extra = productExtras;
        }
        locationCart.products.push(product);
      }
      let priceInclVat = parseFloat($("#subtotal").text());
      locationCart.number_of_products =
        parseInt(locationCart.number_of_products) + numberOfProduct;
      locationCart.amount = parseFloat(locationCart.amount) + priceInclVat;
      updateCartSummary();
      if ($("#footer-space-for-cart").hasClass("d-none")) {
        $("#footer-space-for-cart").removeClass("d-none");
      }
      if (!$("#footer-space-for-cart").hasClass("d-block")) {
        $("#footer-space-for-cart").addClass("d-block");
      }
      $("#product-extra-modal").modal("hide");
      if (productType === "suggested") {
        $(`#suggested-product-${productId}`).remove();
        if ($("#suggested-product div").length === 0) {
          $(".suggested-product-list-container").attr("hidden", true);
          $("#remove-suggested-product-container").attr("hidden", true);
        }
      }

      const existingAmount = $("input#" + productWithCategoryId).val();

      $("input#" + productWithCategoryId).val(
        parseInt(existingAmount) + numberOfProduct
      );
      if (
        numberOfProduct > 0 &&
        $("input#" + productWithCategoryId).hasClass("d-none")
      ) {
        $("input#" + productWithCategoryId).removeClass("d-none");
        $("#remove-product-" + productId).removeClass("d-none");
        $("#product-price-" + productId).addClass("d-none");
      }
    });

    $(document).on("click", ".add-product-with-extra-products", function () {
      let numberOfProduct = parseInt(
        $(this).siblings("input.product-to-order").val()
      );
      let productPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      let sumPriceExtra = subTotal - productPrice * numberOfProduct;
      let sumPriceExtraPerProduct = sumPriceExtra / numberOfProduct;
      numberOfProduct = numberOfProduct + 1;
      subTotal = (
        (productPrice + sumPriceExtraPerProduct) *
        numberOfProduct
      ).toFixed(2);
      $(this).siblings("input.product-to-order").val(numberOfProduct);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
    });

    $(document).on("click", ".remove-product-with-extra-products", function () {
      let numberOfProduct = parseInt(
        $(this).siblings("input.product-to-order").val()
      );
      if (numberOfProduct === 1) {
        return false;
      }
      let productPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      let sumPriceExtra = subTotal - productPrice * numberOfProduct;
      let sumPriceExtraPerProduct = sumPriceExtra / numberOfProduct;
      numberOfProduct = numberOfProduct - 1;
      subTotal = (
        (productPrice + sumPriceExtraPerProduct) *
        numberOfProduct
      ).toFixed(2);
      $(this).siblings("input.product-to-order").val(numberOfProduct);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
    });

    $(document).on("click", ".add-product-extra", function () {
      if (!$(this).siblings("span").hasClass("d-none")) {
        $(this).siblings("span").addClass("d-none");
      }
      if ($(this).siblings(".remove-product-extra").hasClass("d-none")) {
        $(this).siblings(".remove-product-extra").removeClass("d-none");
      }
      if ($(this).siblings("input.product-extra-to-order").hasClass("d-none")) {
        $(this).siblings("input.product-extra-to-order").removeClass("d-none");
      }
      let numberOfProductExtra = parseInt(
        $(this).siblings("input.product-extra-to-order").val()
      );
      let productExtraPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let productId = parseFloat($(this).attr("data-product-id"));
      const cartIndex = $(this).data("cart-index");
      let numberOfProduct = parseInt(
        $("#product-with-extra-products-id-" + productId).val()
      );

      if (cartIndex !== "" && cartIndex >= 0) {
        const productInCart = locationCart.products[cartIndex];
        const productOriginalPrice =
          locationCart.products[cartIndex].original_product_price;
        const updatedProductOriginalPrice =
          productOriginalPrice + productExtraPrice;

        locationCart.products[cartIndex].original_product_price =
          updatedProductOriginalPrice;

        const discountPercent = parseFloat(
          $("#product-discount-percent-input").attr("data-original-value")
        );

        const totalPriceInput = $("#product-discount-price-input");
        const totalPrice =
          productInCart.amount *
          (updatedProductOriginalPrice * (1 - discountPercent / 100));
        totalPriceInput.val(totalPrice).attr("data-original-value", totalPrice);
      }

      numberOfProductExtra = numberOfProductExtra + 1;
      $(this)
        .siblings("input.product-extra-to-order")
        .val(numberOfProductExtra);
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      subTotal = (subTotal + productExtraPrice * numberOfProduct).toFixed(2);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
    });

    $(document).on("click", ".remove-product-extra", function () {
      let numberOfProductExtra = parseInt(
        $(this).siblings("input.product-extra-to-order").val()
      );
      let productExtraPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let productId = parseFloat($(this).attr("data-product-id"));
      const cartIndex = $(this).data("cart-index");
      let numberOfProduct = parseInt(
        $("#product-with-extra-products-id-" + productId).val()
      );

      if (cartIndex !== "" && cartIndex >= 0) {
        const productInCart = locationCart.products[cartIndex];
        const productOriginalPrice =
          locationCart.products[cartIndex].original_product_price;
        const updatedProductOriginalPrice =
          productOriginalPrice - productExtraPrice;

        locationCart.products[cartIndex].original_product_price =
          updatedProductOriginalPrice;

        const discountPercent = parseFloat(
          $("#product-discount-percent-input").attr("data-original-value")
        );

        const totalPriceInput = $("#product-discount-price-input");
        const totalPrice =
          productInCart.amount *
          (updatedProductOriginalPrice * (1 - discountPercent / 100));
        totalPriceInput.val(totalPrice).attr("data-original-value", totalPrice);
      }

      numberOfProductExtra = numberOfProductExtra - 1;
      $(this)
        .siblings("input.product-extra-to-order")
        .val(numberOfProductExtra);
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      subTotal = (subTotal - productExtraPrice * numberOfProduct).toFixed(2);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
      if (numberOfProductExtra === 0) {
        if ($(this).siblings("span").hasClass("d-none")) {
          $(this).siblings("span").removeClass("d-none");
        }
        if (!$(this).hasClass("d-none")) {
          $(this).addClass("d-none");
        }
        if (
          !$(this).siblings("input.product-extra-to-order").hasClass("d-none")
        ) {
          $(this).siblings("input.product-extra-to-order").addClass("d-none");
        }
      }
    });

    $(document).on(
      "change",
      "div.product-qty-container-wrapper input.product-to-order",
      function () {
        let productId = parseInt($(this).attr("data-product-id"));
        let productIndex = locationCart.products.findIndex(
          (obj) => obj.product_id === productId
        );
        let productAmount = parseInt($(this).val());
        let priceInclVat = parseFloat($(this).attr("data-price-incl-vat"));
        if (productAmount > locationCart.products[productIndex].amount) {
          let productAmountDiff =
            productAmount -
            parseInt(locationCart.products[productIndex].amount);
          locationCart.number_of_products =
            parseInt(locationCart.number_of_products) + productAmountDiff;
          let priceInclVatDiff = productAmountDiff * priceInclVat;
          locationCart.amount =
            parseFloat(locationCart.amount) + priceInclVatDiff;
        }

        if (productAmount < locationCart.products[productIndex].amount) {
          let productAmountDiff =
            parseInt(locationCart.products[productIndex].amount) -
            productAmount;
          locationCart.number_of_products =
            parseInt(locationCart.number_of_products) - productAmountDiff;
          let priceInclVatDiff = productAmountDiff * priceInclVat;
          locationCart.amount =
            parseFloat(locationCart.amount) - priceInclVatDiff;
        }
        locationCart.products[productIndex].amount = productAmount;
        updateCartSummary();
      }
    );

    let numberOfExtraProduct;
    $(document).on(
      "focusin",
      "div#product-extra-modal input.product-to-order",
      function () {
        numberOfExtraProduct = $(this).val();
      }
    );

    $(document).on(
      "change",
      "div#product-extra-modal input.product-to-order",
      function () {
        let numberOfProduct = parseInt($(this).val());
        let productPrice = parseFloat($(this).attr("data-price-incl-vat"));
        let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
        let sumPriceExtra = subTotal - productPrice * numberOfExtraProduct;
        let sumPriceExtraPerProduct = sumPriceExtra / numberOfExtraProduct;
        subTotal = (
          (productPrice + sumPriceExtraPerProduct) *
          numberOfProduct
        ).toFixed(2);
        $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
      }
    );

    let numberOfExtraProductExtra;
    $(document).on(
      "focusin",
      "div#product-extra-modal input.product-extra-to-order",
      function () {
        numberOfExtraProductExtra = $(this).val();
      }
    );

    $(document).on(
      "change",
      "div#product-extra-modal input.product-extra-to-order",
      function () {
        let numberOfProductExtra = parseInt($(this).val());
        let productExtraPrice = parseFloat($(this).attr("data-price-incl-vat"));
        let productId = parseInt($(this).attr("data-product-id"));
        let numberOfProduct = parseInt(
          $("#product-with-extra-products-id-" + productId).val()
        );
        let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
        if (numberOfExtraProductExtra > numberOfProductExtra) {
          let numberOfProductExtraDiff =
            numberOfExtraProductExtra - numberOfProductExtra;
          subTotal = (
            subTotal -
            numberOfProductExtraDiff * productExtraPrice * numberOfProduct
          ).toFixed(2);
        }
        if (numberOfExtraProductExtra < numberOfProductExtra) {
          let numberOfProductExtraDiff =
            numberOfProductExtra - numberOfExtraProductExtra;
          subTotal = (
            subTotal +
            numberOfProductExtraDiff * productExtraPrice * numberOfProduct
          ).toFixed(2);
        }
        $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
      }
    );

    $(document).on("click", ".add-product", function () {
      let productId = parseInt($(this).attr("data-product-id"));
      let productWithCategoryId = $(this).attr("data-product-with-category-id");
      const productContainer = $(this).closest(".shop-product-container");
      let isForceProductExterBox =
        productContainer.attr("data-force-extra-box") == 1 ? true : false;
      let productType = productContainer.attr("data-product-type");

      let productIndex = locationCart.products.findIndex(
        (obj) =>
          obj.product_id == productId &&
          (productWithCategoryId !== "" && productWithCategoryId !== undefined
            ? obj.product_with_category_id == productWithCategoryId
            : true)
      );

      if (
        $(this).attr("data-has-product-extra") == 1 &&
        $(this).attr("data-force-product-extra-popup") == 1 &&
        !isForceProductExterBox
      ) {
        getProductExtraModal(productId, productWithCategoryId);
        if (productType === "suggested") {
          setTimeout(() => {
            $("#add-to-cart-button").attr("data-product-type", productType);
          }, 300);
        }
        return false;
      } else {
        if (!$(this).siblings("span").hasClass("d-none")) {
          $(this).siblings("span").addClass("d-none");
        }
        if ($(this).siblings(".remove-product").hasClass("d-none")) {
          $(this).siblings(".remove-product").removeClass("d-none");
        }
        if ($(this).siblings("input.product-to-order").hasClass("d-none")) {
          $(this).siblings("input.product-to-order").removeClass("d-none");
        }

        if (!locationCart.number_of_products) {
          locationCart.order_discount_percent = 0;
        }

        let productAmount;
        let priceInclVat = parseFloat($(this).attr("data-price-incl-vat"));
        if (productIndex === -1) {
          productAmount = 1;
          let product = {
            product_id: productId,
            original_product_price: priceInclVat,
            amount: productAmount,
            discount_percent: locationCart.order_discount_percent || 0,
            discount_price: 0,
            product_with_category_id: productWithCategoryId,
          };
          locationCart.products.push(product);
          locationCart.amount =
            parseFloat(locationCart.amount) +
            priceInclVat * (1 - product.discount_percent / 100);
        } else {
          const priceInclDiscount =
            locationCart.products[productIndex].original_product_price;
          productAmount =
            parseInt(locationCart.products[productIndex].amount) + 1;
          locationCart.products[productIndex].amount = productAmount;
          locationCart.amount =
            parseFloat(locationCart.amount) +
            priceInclDiscount *
              (1 - locationCart.products[productIndex].discount_percent / 100);
        }

        locationCart.amount_without_discount = floor(
          locationCart.products.reduce(
            (total, product) =>
              total +
              parseFloat(product.amount * product.original_product_price),
            0
          )
        );
        locationCart.number_of_products =
          parseInt(locationCart.number_of_products) + 1;
        updateCartSummary();
        if (shop_type != "pos") {
          $("input#" + productWithCategoryId).val(productAmount);
        }
        if ($("#footer-space-for-cart").hasClass("d-none")) {
          $("#footer-space-for-cart").removeClass("d-none");
        }
        if (!$("#footer-space-for-cart").hasClass("d-block")) {
          $("#footer-space-for-cart").addClass("d-block");
        }

        if (productType == "suggested") {
          productContainer.remove();
          if ($("#suggested-product div").length === 0) {
            $(".suggested-product-list-container").attr("hidden", true);
            $("#remove-suggested-product-container").attr("hidden", true);
          }
        }
      }
    });

    $(document).on("click", ".close-suggested-product", function () {
      $(this)
        .closest(".shop-product-container")
        .fadeOut(200, function () {
          $(this).remove();
        });
      setTimeout(() => {
        if ($("#suggested-product div").length === 0) {
          $(".suggested-product-list-container").attr("hidden", true);
          $("#remove-suggested-product-container").attr("hidden", true);
        }
      }, 300);
    });
    $(document).on(
      "input",
      "#product-discount-price-input, #product-discount-percent-input, #discount-percent-input",
      function () {
        if (this.value.length > 1 && this.value.indexOf(0) == 0) {
          $(this).val(Math.abs(this.value));
        }
      }
    );

    $(document).on(
      "keydown",
      "#product-discount-price-input, #discount-percent-input, #product-discount-percent-input, .product-to-order",
      function (event) {
        if (event.key === "-") {
          event.preventDefault();
        }
      }
    );

    $(document).on(
      "input",
      "#product-discount-percent-input, #discount-percent-input",
      function () {
        const value = parseFloat(this.value);

        if (this.value.length > 1 && this.value.indexOf(0) == 0) {
          $(this).val(Math.abs(this.value));
        }

        if (value > 100) {
          this.value = 100;
        }
      }
    );

    $(document).on("input", "#product-discount-price-input", function () {
      if (this.value.length > 1 && this.value.indexOf(0) == 0) {
        $(this).val(Math.abs(this.value));
      }
    });

    $(document).on("change", "#product-discount-percent-input", function () {
      const productIndex = $(this).data("cart-index");
      const incomingPercent = parseFloat($(this).val());

      const productPrice =
        locationCart.products[productIndex].original_product_price;
      const productQuantity = locationCart.products[productIndex].amount;
      const originalProductTotalPrice = productPrice * productQuantity;
      const incomingDiscountPrice =
        originalProductTotalPrice * (1 - incomingPercent / 100);

      $("#product-discount-price-input")
        .val(incomingDiscountPrice)
        .attr("data-original-value", incomingDiscountPrice);
      $("#product-discount-percent-input").attr(
        "data-original-value",
        incomingPercent
      );
    });

    $(document).on("change", "#product-discount-price-input", function () {
      const productIndex = $(this).data("cart-index");
      const incomingPrice = parseFloat($(this).val());
      const productPrice =
        locationCart.products[productIndex].original_product_price;
      const productQuantity = locationCart.products[productIndex].amount;
      const discountedProductPrice = incomingPrice / productQuantity;
      const discountPercent = (1 - discountedProductPrice / productPrice) * 100;

      $("#product-discount-percent-input")
        .val(discountPercent > 0 ? discountPercent : 0)
        .attr("data-original-value", discountPercent);
    });

    $(document).on("keydown", "#discount-percent-input", function (e) {
      if (e.keyCode === 13) {
        emptyCartOnModalClose = false;
        e.preventDefault();
        const orderDiscountpercent =
          parseFloat($("#discount-percent-input").val()) || 0;
        locationCart.order_discount_percent = orderDiscountpercent;

        locationCart.products.map((product, index) => {
          const bestValuePercent =
            orderDiscountpercent > product.discount_percent
              ? orderDiscountpercent
              : product.discount_percent;
          locationCart.products[index].discount_percent =
            Math.round(bestValuePercent * 100) / 100;
        });

        $("#cart-modal").modal("hide");
        $("#product-extra-modal").modal("hide");
        $(".modal-backdrop").remove();
        getCartModal();
      }
    });

    $(document).on("click", "#add-discount", function (e) {
      emptyCartOnModalClose = false;
      e.preventDefault();
      const orderDiscountpercent =
        parseFloat($("#discount-percent-input").val()) || 0;
      locationCart.order_discount_percent = orderDiscountpercent;

      locationCart.products.map((product, index) => {
        const bestValuePercent =
          orderDiscountpercent > product.discount_percent
            ? orderDiscountpercent
            : product.discount_percent;
        locationCart.products[index].discount_percent = floor(bestValuePercent);
      });

      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total +
            parseFloat(
              (product.amount * product.original_product_price).toFixed(2)
            ),
          0
        )
      );

      $("#cart-modal").modal("hide");
      $("#product-extra-modal").modal("hide");
      $(".modal-backdrop").remove();
      getCartModal();
    });

    $(document).on("click", "#show-discount", function () {
      $(this).hide();
      $(".show-discount-percent").removeClass("d-none");
    });

    $(document).on("click", "#update-extra-product-button", function () {
      emptyCartOnModalClose = false;
      let productIndex = parseInt($(this).data("cart-index"));
      let productExtraArray = $("input.product-extra-to-order");
      let productPrice = parseFloat(
        $("#productExtraModalLabel").data("product-price")
      );
      let productExtraTextArray = $("input.product-extra-text-to-order");
      let comment = $("textarea[name='comment']").val();
      let productDiscountPercent =
        $("#product-discount-percent-input").attr("data-original-value") || 0;
      let productDiscountPrice =
        $("#product-discount-price-input").attr("data-original-value") || 0;
      let productExtras = [];
      let originalProductPrice = productPrice;

      productExtraArray.each(function () {
        if (parseInt($(this).val()) > 0) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: parseInt($(this).val()),
            original_price: parseFloat($(this).attr("data-price-incl-vat")),
          };
          productExtras.push(productExtra);
          originalProductPrice +=
            parseInt($(this).val()) *
            parseFloat($(this).attr("data-price-incl-vat"));
        }
      });
      productExtraTextArray.each(function () {
        if ($(this).is(":checked")) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: 1,
            checked: true,
            original_price: 0,
          };
          productExtras.push(productExtra);
          originalProductPrice += 0;
        }
      });
      productExtras.sort(function (a, b) {
        return a["product_extra_id"] > b["product_extra_id"]
          ? 1
          : a["product_extra_id"] < b["product_extra_id"]
          ? -1
          : 0;
      });
      locationCart.products[productIndex].original_product_price =
        originalProductPrice;
      locationCart.products[productIndex].product_extra = productExtras;
      locationCart.products[productIndex].comment = comment;
      locationCart.products[productIndex].discount_price = productDiscountPrice;
      locationCart.products[productIndex].discount_percent =
        Math.round(productDiscountPercent * 100) / 100;
      $("#cart-modal").modal("hide");
      $("#product-extra-modal").modal("hide");
      $(".modal-backdrop").remove();
      getCartModal();
    });

    $(document).on("click", "td.product-title", function () {
      let productId = parseInt($(this).attr("data-product-id"));
      let productIndex = parseInt($(this).data("cart-index"));
      let comment = locationCart.products[productIndex].comment;
      const productPrice = $(this)
        .closest("tr")
        .find(".cart-product-amount")
        .data("product-price-incl-vat");

      if (!locationCart.products[productIndex].original_product_price) {
        locationCart.products[productIndex].original_product_price =
          productPrice;
      }

      let discountPrice =
        locationCart.products[productIndex].amount * productPrice;
      let discountPercent =
        locationCart.products[productIndex].discount_percent;
      locationCart.products[productIndex].discount_price = discountPrice;

      if (locationCart.products[productIndex].product_extra) {
        const totalProductExtraPrice = locationCart.products[
          productIndex
        ].product_extra.reduce(
          (total, extraProduct) =>
            total + extraProduct.amount * extraProduct.original_price,
          0
        );
        const individualOriginalProductPrice = parseFloat(
          $(this).data("product-price")
        );
        locationCart.products[productIndex].original_product_price =
          individualOriginalProductPrice + totalProductExtraPrice;
      }

      if ("product_extra" in locationCart.products[productIndex]) {
        let productExtra = locationCart.products[productIndex].product_extra;
        getProductExtraModal(
          productId,
          locationCart.products[productIndex].product_with_category_id,
          discountPrice,
          discountPercent,
          1,
          productIndex,
          comment,
          productExtra
        );
      } else {
        getProductExtraModal(
          productId,
          locationCart.products[productIndex].product_with_category_id,
          discountPrice,
          discountPercent,
          1,
          productIndex,
          comment
        );
      }
    });

    function getProductExtraModal(
      productId,
      productWithCategoryId = "",
      discountPrice = 0,
      discountPercent = 0,
      update = 0,
      productIndex = null,
      comment = "",
      productExtra = [""]
    ) {
      if ($("#product-extra-modal").length) {
        $("#product-extra-modal").remove();
      }

      let data = {
        location_id: locationId,
        product_id: productId,
        location_type: locationCart.location_type,
        product_extra: productExtra,
        update: update,
        comment: comment,
        discount_price: discountPrice,
        discount_percent: discountPercent,
        shop_type: p === "events_self_service" ? "self_service" : "pos",
        product_with_category_id: productWithCategoryId,
      };
      if (productIndex !== null) {
        data.cart_index = productIndex;
      }
      if (locationCart.promo_code != "") {
        data.promo_code = locationCart.promo_code;
      }
      $.ajax({
        type: "POST",
        url: "/ajax/order/get_product_extra",
        data: data,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#event-public-list-organizer").after(data.html);
            $("#product-extra-modal").modal("show");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    updateCartSummary = function () {
      const $amountElement = $("#location-cart-summary-amount");
      const $cartAmountElement = $("#location-cart-summary-number-of-products");

      $cartAmountElement.removeClass("scale-down").addClass("scale-up");
      setTimeout(function () {
        $cartAmountElement.text(locationCart.number_of_products);
        $cartAmountElement.removeClass("scale-up").addClass("scale-down");
      }, 500);

      $amountElement.removeClass("scale-down").addClass("scale-up");
      setTimeout(function () {
        $amountElement.text(locationCart.amount.toFixed(2).replace(".", ","));
        $amountElement.removeClass("scale-up").addClass("scale-down");
      }, 500);

      if (!locationCart.amount_without_discount) {
        locationCart.amount_without_discount = 0;
      }
      $("#original-total-amount").text(
        locationCart.amount_without_discount.toFixed(2).replace(".", ",")
      );
      if (
        locationCart.number_of_products == 0 &&
        !$("#location-cart-summary").hasClass("d-none")
      ) {
        $("#location-cart-summary").addClass("d-none");
      }
      if (
        locationCart.number_of_products > 0 &&
        $("#location-cart-summary").hasClass("d-none")
      ) {
        $("#location-cart-summary").removeClass("d-none");
      }
    };

    $(document).on("click", ".remove-product", function () {
      let productId = parseInt($(this).attr("data-product-id"));
      let productWithCategoryId = $(this).attr("data-product-with-category-id");

      let productIndex = locationCart.products.findIndex((obj) =>
        obj.product_with_category_id !== "" &&
        obj.product_with_category_id !== undefined
          ? obj.product_id == productId &&
            obj.product_with_category_id == productWithCategoryId
          : obj.product_id === productId
      );

      if (locationCart.products[productIndex].amount === 0) {
        return;
      }

      let productAmount =
        parseInt(locationCart.products[productIndex].amount) - 1;
      locationCart.products[productIndex].amount = productAmount;
      let priceInclVat = parseFloat($(this).attr("data-price-incl-vat"));
      locationCart.number_of_products =
        parseInt(locationCart.number_of_products) - 1;
      locationCart.amount = parseFloat(locationCart.amount) - priceInclVat;
      updateCartSummary();
      $("input#" + productWithCategoryId).val(productAmount);

      // if (productAmount === 0) {
      //   if (!$("input#" + productWithCategoryId).hasClass("d-none")) {
      //     $("input#" + productWithCategoryId).addClass("d-none");
      //   }
      //   if ($(this).siblings("span").hasClass("d-none")) {
      //     $(this).siblings("span").removeClass("d-none");
      //   }
      //   $(this).addClass("d-none");
      //   if (!$("#footer-space-for-cart").hasClass("d-none")) {
      //     $("#footer-space-for-cart").addClass("d-none");
      //   }
      //   if ($("#footer-space-for-cart").hasClass("d-block")) {
      //     $("#footer-space-for-cart").removeClass("d-block");
      //   }
      // }
    });

    $(document).on("click", ".cart-open", function () {
      locationCart.order_storage_identifier = "";
      getCartModal();
    });

    $(document).on("click", ".add-tips", function () {
      let tipsPercent = parseInt($(this).attr("data-tips-percent"));
      if (tipsPercent === locationCart.tips_percent) {
        locationCart.tips_percent = 0;
        $(this).removeClass("btn-primary");
        $(this).addClass("btn-light");
      } else {
        locationCart.tips_percent = tipsPercent;
        $(".add-tips").removeClass("btn-primary");
        $(".add-tips").addClass("btn-light");
        $(this).removeClass("btn-light");
        $(this).addClass("btn-primary");
      }
      updateTips();
      if (locationCart.tips_percent == 0) {
        if (!$(".cart-summary-total").hasClass("d-none")) {
          $(".cart-summary-total").addClass("d-none");
        }
        if (!$(".cart-summary-tips").hasClass("d-none")) {
          $(".cart-summary-tips").addClass("d-none");
        }
      } else {
        if ($(".cart-summary-total").hasClass("d-none")) {
          $(".cart-summary-total").removeClass("d-none");
        }
        if ($(".cart-summary-tips").hasClass("d-none")) {
          $(".cart-summary-tips").removeClass("d-none");
        }
      }
    });

    function updateTips() {
      $("#cart-tips-percent").text(locationCart.tips_percent);
      let tips = Math.round(
        (locationCart.amount * locationCart.tips_percent) / 100
      );
      $("#cart-tips").text(tips.toFixed(2).replace(".", ","));
      let amountWithTips = (locationCart.amount + tips).toFixed(2);
      $("#cart-amount-with-tips").text(amountWithTips.replace(".", ","));
      $("#order-button-amount").text(amountWithTips.replace(".", ","));
      $("#tipping").val(
        ((locationCart.amount * locationCart.tips_percent) / 100)
          .toFixed(2)
          .replace(".", ",")
      );
    }

    $(document).on("click", ".cart-add-product", function () {
      let productId = $(this).data("product-id");
      let productIndex = parseInt($(this).data("cart-index"));
      let productAmount = parseInt(locationCart.products[productIndex].amount);
      let cartAmount = parseInt(locationCart.number_of_products);
      productAmount += 1;
      cartAmount += 1;
      locationCart.products[productIndex].amount = productAmount;
      locationCart.number_of_products = cartAmount;
      let productPrice = parseFloat(
        $(this).siblings(".cart-product-amount").data("product-price-incl-vat")
      );
      let productTotalPrice = (productPrice * productAmount).toFixed(2);
      $(this)
        .closest("td")
        .find(".product-total-price")
        .text(productTotalPrice.replace(".", ","));
      $(this).siblings(".cart-product-amount").text(productAmount);
      $("input#product-id-" + productId).val(productAmount);
      $("input#product-cart-index-" + productIndex).val(productAmount);
      addPriceToCart(productPrice);
      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total + parseFloat(product.amount * product.original_product_price),
          0
        )
      );
      updateCartSummary();
    });

    $(document).on("click", ".cart-remove-product", function () {
      let productId = $(this).data("product-id");
      let productIndex = parseInt($(this).data("cart-index"));
      let productAmount = parseInt(locationCart.products[productIndex].amount);
      let cartAmount = parseInt(locationCart.number_of_products);
      productAmount -= 1;
      cartAmount -= 1;
      locationCart.products[productIndex].amount = productAmount;
      locationCart.number_of_products = cartAmount;
      let productPrice = parseFloat(
        $(this).siblings(".cart-product-amount").data("product-price-incl-vat")
      );
      subtractPriceToCart(productPrice);
      $("input#product-id-" + productId).val(productAmount);
      $("input#product-cart-index-" + productIndex).val(productAmount);
      if (productAmount === 0) {
        $(this).closest("tr").remove();
        if (!$("input#product-id-" + productId).hasClass("d-none")) {
          $("input#product-id-" + productId).addClass("d-none");
        }
        if (
          $("input#product-id-" + productId)
            .siblings("span")
            .hasClass("d-none")
        ) {
          $("input#product-id-" + productId)
            .siblings("span")
            .removeClass("d-none");
        }
        $("input#product-id-" + productId)
          .siblings("button.remove-product")
          .addClass("d-none");
        if (locationCart.number_of_products == 0) {
          $("#cart-modal").modal("hide");
          $(".modal-backdrop").remove();
        }
        locationCart.products[productIndex].discount_percent = 0;
      } else {
        locationCart.products[productIndex].amount = productAmount;
        let productTotalPrice = (productPrice * productAmount).toFixed(2);
        $(this)
          .closest("td")
          .find(".product-total-price")
          .text(productTotalPrice.replace(".", ","));
        $(this).siblings(".cart-product-amount").text(productAmount);
      }

      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total +
            parseFloat(
              (product.amount * product.original_product_price).toFixed(2)
            ),
          0
        )
      );
      updateCartSummary();
    });

    $(document).on("change", "#table_number", function () {
      let tableNumber = $(this).val();
      locationCart.table_number = tableNumber;
    });

    $(document).on("change", "#name", function () {
      let name = $(this).val();
      locationCart.name = name;
    });

    $(document).on("change", "#phone_number", function () {
      let phoneNumber = $(this).val();
      locationCart.phone_number = phoneNumber;
    });

    $(document).on("change", "#pickup_date", function () {
      let pickupDate = $(this).val();
      locationCart.pickup_date = pickupDate;
    });

    $(document).on("change", "#pickup_time", function () {
      let pickupTime = $(this).val();
      const minPickupTime = $(this).attr("data-min-time");
      const maxPickupTime = $(this).attr("data-max-time");
      if (pickupTime < minPickupTime) {
        $("#pickup-time-warning").attr("hidden", false);
        $(".order-button").attr("disabled", true);
        $(this).focus();
        return false;
      }

      if (pickupTime > maxPickupTime) {
        $("#pickup-time-warning").attr("hidden", false);
        $(".order-button").attr("disabled", true);
        $(this).focus();
        return false;
      }
      $("#pickup-time-warning").attr("hidden", true);
      $(".order-button").each(function () {
        if (
          $(this).is("[data-toggle]") &&
          ($(this).data("payment-method") === "nets_card" ||
            $(this).data("payment-method") === "nets_vipps")
        ) {
          $(this).prop("disabled", true);
        } else {
          $(this).prop("disabled", false);
        }
      });
      locationCart.pickup_time = pickupTime;
    });

    function subtractPriceToCart(productPrice) {
      locationCart.amount = locationCart.amount - productPrice;
      updateCart();
    }

    function addPriceToCart(productPrice) {
      locationCart.amount = locationCart.amount + productPrice;
      updateCart();
    }

    function updateCart() {
      $("#cart-amount").text(locationCart.amount.toFixed(2));
      let tips = Math.round(
        (locationCart.amount * locationCart.tips_percent) / 100
      );
      $("#cart-tips").text(tips.toFixed(2).replace(".", ","));
      let amountWithTips = (locationCart.amount + tips).toFixed(2);
      $("#cart-amount-with-tips").text(amountWithTips.replace(".", ","));
      $("#order-button-amount").text(amountWithTips.replace(".", ","));
    }

    $(document).on("click", "#cart-comment-button", function () {
      if ($("#cart-comment").hasClass("d-none")) {
        $("#cart-comment").removeClass("d-none");
      } else {
        $("#cart-comment").addClass("d-none");
      }
      return false;
    });

    $(document).on("click", "#product-extra-comment-button", function () {
      if ($("#product-extra-comment").hasClass("d-none")) {
        $("#product-extra-comment").removeClass("d-none");
      }
      if ($("#product-extra-comment-label").hasClass("d-none")) {
        $("#product-extra-comment-label").removeClass("d-none");
      }
      $(this).remove();
    });

    $(document).on("change", "#cart-comment", function () {
      locationCart.comment = $("#cart-comment").val();
    });

    $(document).on("click", "#term-button", function () {
      return false;
    });

    $(document).on("click", ".get-products", function () {
      let btn = $(this);
      let locationId = $(this).attr("data-location-id");
      let locationType = $(this).attr("data-location-type");

      if (locationCart.number_of_products > 0) {
        changeLocationType(locationId, locationType, locationCart).done(
          function (data) {
            if (data.are_products_available === false) {
              let confirmMessage = btn.attr(
                "data-location-type-confirm-message"
              );
              if (!confirm(confirmMessage)) {
                $(
                  "input[name='inlineRadioOptions'][data-location-type='" +
                    locationType +
                    "']"
                ).prop("checked", false);
                $(
                  "input[name='inlineRadioOptions'][data-location-type='" +
                    locationCart.location_type +
                    "']"
                ).prop("checked", true);
                return false;
              }
              emptyCart();
            } else {
              locationCart.amount = data.cart_total_price;
            }
            locationCart.location_type = locationType;
            updateCartSummary();
            getProducts(locationId, locationType, locationCart.products);
          }
        );
      } else {
        locationCart.location_type = locationType;
        getProducts(locationId, locationType);
      }
    });

    $(document).on("click", "#open-promo-code-form-button", function () {
      if (!$("#promo-code-alert-message").hasClass("d-none")) {
        $("#promo-code-alert-message").addClass("d-none");
      }
      if ($("#location-promo-code-form-wrapper").css("display") == "none") {
        $("#location-promo-code-form-wrapper").css("display", "");
      }
      if ($("#location-promo-code-form").hasClass("d-none")) {
        $("#location-promo-code-form").removeClass("d-none");
      }
      if ($("#location-promo-code-form-wrapper").hasClass("d-none")) {
        $("#location-promo-code-form-wrapper").removeClass("d-none");
      } else {
        $("#location-promo-code-form-wrapper").addClass("d-none");
      }
    });

    $(document).on("keypress", "#promo_code", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        activatePromoCode();
      }
    });

    $(document).on("click", "#promo-code-button", function () {
      activatePromoCode();
    });

    function activatePromoCode() {
      let promoCode = $("#promo_code").val();
      if (promoCode == "") {
        return false;
      }

      let promoCodeIsValid = false;
      checkPromoCode(locationId, promoCode, function (promoCodeIsValidBool) {
        promoCodeIsValid = promoCodeIsValidBool;
      });

      setTimeout(function () {
        if (!promoCodeIsValid) {
          if ($("#promo-code-alert-message").hasClass("alert-success")) {
            $("#promo-code-alert-message").removeClass("alert-success");
          }
          if (!$("#promo-code-alert-message").hasClass("alert-danger")) {
            $("#promo-code-alert-message").addClass("alert-danger");
          }
          if ($("#promo-code-alert-message").hasClass("d-none")) {
            $("#promo-code-alert-message").removeClass("d-none");
          }
          if ($("#promo-code-alert-message").css("display") == "none") {
            $("#promo-code-alert-message").css("display", "");
          }
          $("#promo-code-alert-message").text(
            $("#promo-code-button").attr("data-promo-code-error-message")
          );
          $("#promo-code-alert-message").fadeOut(5000);
          setTimeout(function () {
            $("#promo-code-alert-message").addClass("d-none");
          }, 5000);
          return false;
        } else {
          if ($("#promo-code-alert-message").hasClass("alert-danger")) {
            $("#promo-code-alert-message").removeClass("alert-danger");
          }
          if (!$("#promo-code-alert-message").hasClass("alert-success")) {
            $("#promo-code-alert-message").addClass("alert-success");
          }
          if ($("#promo-code-alert-message").hasClass("d-none")) {
            $("#promo-code-alert-message").removeClass("d-none");
          }
          if ($("#promo-code-alert-message").css("display") == "none") {
            $("#promo-code-alert-message").css("display", "");
          }
          $("#promo-code-alert-message").text(
            $("#promo-code-button").attr("data-promo-code-success-message")
          );
          locationCart.promo_code = promoCode;
          getProducts(locationId, locationType, null, promoCode, p);
          $("#location-promo-code-form-wrapper").fadeOut(5000);
          $("#location-promo-code-form").addClass("d-none");
          setTimeout(function () {
            $("#location-promo-code-form-wrapper").addClass("d-none");
          }, 5000);
        }
      }, 1000);
    }

    function checkPromoCode(locationId, promoCode, promoCodeIsValid) {
      $.ajax({
        type: "POST",
        url: "/ajax/event/check_promo_code",
        data: {
          location_id: locationId,
          promo_code: promoCode,
        },
      })
        .done(function (data) {
          if (
            data.promo_code_is_valid !== undefined &&
            data.promo_code_is_valid !== null &&
            data.promo_code_is_valid == true
          ) {
            promoCodeIsValid(true);
          } else {
            promoCodeIsValid(false);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function changeLocationType(locationId, locationType, locationCart) {
      return $.ajax({
        type: "POST",
        url: "/ajax/event/change_location_type",
        data: {
          location_id: locationId,
          location_type: locationType,
          products: locationCart.products,
        },
      })

        .done(function (data) {
          return {
            are_products_available: data.are_products_available,
            cart_total_price: data.cart_total_price,
          };
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getProducts(
      locationId,
      locationType,
      products = null,
      promoCode = null,
      shop_type = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/event/get_products",
        data: {
          location_id: locationId,
          location_type: locationType,
          products: products,
          promo_code: promoCode,
          shop_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#location-products").remove();
            if ($("#location-type-buttons").length) {
              $("#location-type-buttons").after(data.html);
            } else if ($("#location-promo-code-form-wrapper").length) {
              $("#location-promo-code-form-wrapper").after(data.html);
            } else {
              $("#location").after(data.html);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function emptyCart() {
      locationCart.order_storage_identifier = "";
      locationCart.number_of_products = 0;
      locationCart.amount = 0;
      locationCart.products = [];
      locationCart.order_discount_percent = 0;
      locationCart.amount_without_discount = 0;
      locationCart.customer_id = "";
      locationCart.checkout_type = "";
      locationCart.table_customer_id = 0;
      locationCart.table_number = "";
      orderStorageIds = null;
      locationCart.take_away_address = "";
      locationCart.first_name = "";
      locationCart.last_name = "";
      updateCartSummary();
    }

    emptyAllCart = function () {
      emptyCart();
    };

    if ($(".clear-shopping-cart").length) {
      emptyCart();
    }

    $(document).on("click", ".order-button", function (e) {
      locationCart.order_storage_identifier = "";
      const paymentMethod = $(this).attr("data-payment-method");
      locationCart.payment_method = paymentMethod;
      if (paymentMethod === "payment_terminal") {
        locationCart.terminal_id = $(this).attr("data-terminal-id");
      }
    });

    let order_ajax_lock = false;
    $(document).on("submit", "#order_register_form", function (e) {
      $("div.loader").remove();
      e.preventDefault();

      if (locationCart.products.length === 0) {
        return;
      }

      if (locationCart.payment_method === "payment_split") {
        getPaymentSplitModal(locationCart);
        return;
      }

      if (shop_type === "take-away") {
        locationCart.pickup_date = $("#pickup_date").val();
        locationCart.pickup_time = $("#pickup_time").val();
        locationCart.take_away_address = $("#address").val();
        locationCart.first_name = $("#first_name").val();
        locationCart.last_name = $("#last_name").val();
      }
      if (
        locationCart.payment_method == "nets_card" ||
        locationCart.payment_method == "nets_vipps"
      ) {
        localStorage.setItem("location-cart", JSON.stringify(locationCart));
      }

      if (!order_ajax_lock) {
        $(this).append('<div class="loader"></div>');

        order_ajax_lock = true;

        if ($("#pick-up-at-the-bar").length) {
          locationCart.pick_up_at_the_bar = true;
        }

        let newOrderData = {
          location_id: locationId,
          location_type: locationCart.location_type,
          table_number: locationCart.table_number,
          products: locationCart.products,
          tips_percent: locationCart.tips_percent,
          payment_method: locationCart.payment_method,
          terminal_id: locationCart.terminal_id,
          comment: locationCart.comment,
          name: locationCart.name,
          phone_number: locationCart.phone_number,
          first_name: $("#first_name").val() ?? "",
          last_name: $("#last_name").val() ?? "",
          pickup_date: locationCart.pickup_date,
          pickup_time: locationCart.pickup_time,
          promo_code: locationCart.promo_code,
          custom_pickup_time: $("#custom_pickup_time").val(),
          pick_up_at_the_bar: locationCart.pick_up_at_the_bar,
          order_storage_identifier: locationCart.order_storage_identifier,
          userId,
          customer_number: locationCart.customer_id,
          table_customer_id: locationCart.table_customer_id ?? 0,
          take_away_address: locationCart.take_away_address,
          shop_type,
          organizer_id: organizerId,
        };

        if (orderStorageIds !== null) {
          newOrderData.order_storage_ids = orderStorageIds;
        }
        if (shop_type === "automat") {
          newOrderData.shop_type = "automat";
        }
        $.ajax({
          type: "POST",
          url: "/ajax/order/new",
          data: newOrderData,
        })
          .done(function (data) {
            if (
              data.orderProducts &&
              data.order &&
              data.organizer &&
              (locationCart.payment_method === "payment_preprint" ||
                locationCart.payment_method === "payment_cash")
            ) {
              passDataToWebView(
                e,
                "pre_print_order",
                data.orderProducts,
                data.printerIP,
                data.printerType,
                data.organizer,
                data.order
              );
            }

            if (
              data.payment_redirect_url !== undefined &&
              data.payment_redirect_url !== null
            ) {
              document.location.href = data.payment_redirect_url;
            }

            if (
              data.confirmation_code !== undefined &&
              data.confirmation_code !== null
            ) {
              const orderHtml =
                data.order_html !== undefined && data.order_html !== null
                  ? data.order_html
                  : "";
              $(".modal-backdrop").remove();
              if (
                $("#content").hasClass("is-employee") &&
                (locationCart.payment_method == "payment_terminal" ||
                  locationCart.payment_method == "payment_cash" ||
                  locationCart.payment_method == "payment_method_coupon" ||
                  locationCart.payment_method == "payment_preprint" ||
                  locationCart.payment_method == "none")
              ) {
                emptyCart();
                $("#cart-modal").modal("hide");
                $(".modal-backdrop").remove();
                $(".confirmation-toast").remove();
                $("#content").append(
                  '<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                    '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                    '  <div class="toast-header">' +
                    '    <strong class="mr-auto">Ordre registrert</strong>' +
                    "    <small></small>" +
                    '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                    '      <span aria-hidden="true">&times;</span>' +
                    "    </button>" +
                    "  </div>" +
                    '  <div class="toast-body">' +
                    orderHtml +
                    "  </div>" +
                    "</div>" +
                    "</div>"
                );
                $(".toast").toast("show");
                setTimeout(function () {
                  order_ajax_lock = false;
                  cartQuickPaymentTerminalLock = false;
                  if (data.pin_required) {
                    document.location.href = `/user/organizer_login?redirect_url=${encodeURIComponent(
                      document.location.pathname
                    )}`;
                  } else if (data.login_reqiured) {
                    document.location.href = "/logout";
                  }
                }, 3000);
                emptyCartOnModalClose = true;

                localStorage.removeItem("saved_table_id");
              } else {
                localStorage.removeItem("saved_table_id");
                document.location.href =
                  `/order/confirmation/${data.confirmation_code}` +
                  (shop_type
                    ? `?shop_type=${shop_type}&table_number=${newOrderData.table_number}`
                    : "");
              }
            }
          })
          .fail(function (data) {
            if (
              data.responseJSON !== undefined &&
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              if (!$("#order-button-wrapper").is(":visible")) {
                alert(data.responseJSON.error_message);
              } else {
                blinkButton(
                  $(`.terminal-button-${locationCart.terminal_id}`),
                  "red"
                );
                $(".loader").attr("hidden", true);
                prependAlert(
                  "#order-button-wrapper, #terminal-modal-body",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#order_register_form"
                );
              }
              $("div.loader").remove();
            }
            order_ajax_lock = false;
            cartQuickPaymentTerminalLock = false;
            $("div.loader").remove();
          })
          .always(function () {
            locationCart.order_storage_identifier = "";
            if (
              !(
                $("#content").hasClass("is-employee") &&
                (locationCart.payment_method == "payment_terminal" ||
                  locationCart.payment_method == "payment_cash" ||
                  locationCart.payment_method == "payment_method_coupon")
              )
            ) {
              order_ajax_lock = false;
            }
          });
      }
      return false;
    });

    $(document).on("click", ".shop-employee-mode .shop-product", function () {
      const productContainer = $(this).closest(".shop-product-container");

      $(".add-product", productContainer).click();
      const shopProduct = $(this);
      shopProduct.focus();
      if (this._focusTimeout) {
        clearTimeout(this._focusTimeout);
      }

      this._focusTimeout = setTimeout(() => {
        shopProduct.blur();
        delete this._focusTimeout;
      }, 100);
    });

    let cartQuickPaymentTerminalLock = false;
    $(document).on(
      "click",
      ".cart-quick-payment-terminal .order-button",
      function () {
        if (!cartQuickPaymentTerminalLock) {
          cartQuickPaymentTerminalLock = true;
          getCartModal(true);
        }
      }
    );

    $(document).on("click", "#reset-shopping-cart", function () {
      if (locationCart.products.length === 0) {
        return;
      }
      $("#emptyCartModel").modal("show");
    });
    $(document).on("click", "#empty-cart-confirm", function () {
      emptyCart();
      $("#cart-modal").modal("hide");
      $("#emptyCartModel").modal("hide");
      $(".modal-backdrop").remove();
      popupAlert();
      return false;
    });

    let isNameRequired = false;
    let isPhoneNumberRequired = false;
    $(document).on("click", "#pick-up-at-the-bar-button", function () {
      if (isNameRequired === false && $("#name").prop("required")) {
        isNameRequired = true;
      }
      if (
        isPhoneNumberRequired === false &&
        $("#phone_number").prop("required")
      ) {
        isPhoneNumberRequired = true;
      }
      if ($(this).hasClass("btn-primary")) {
        locationCart.pick_up_at_the_bar = true;
        $(this).removeClass("btn-primary");
        $(this).addClass("btn-light");
        $("#table_number").attr("required", false);
        $("#name").attr("required", false);
        $("#phone_number").attr("required", false);
        $("#table_number").val("");
        $("#name").val("");
        $("#phone_number").val("");
        if (!$(".get-delivered-to-table").hasClass("d-none")) {
          $(".get-delivered-to-table").addClass("d-none");
        }
        if ($(this).closest("div").hasClass("col-4")) {
          $(this).closest("div").removeClass("col-4");
          $(this).closest("div").addClass("col-8");
        }
      } else {
        locationCart.pick_up_at_the_bar = false;
        $(this).removeClass("btn-light");
        $(this).addClass("btn-primary");
        if ($(".get-delivered-to-table").hasClass("d-none")) {
          $(".get-delivered-to-table").removeClass("d-none");
        }
        if ($(this).closest("div").hasClass("col-8")) {
          $(this).closest("div").removeClass("col-8");
          $(this).closest("div").addClass("col-4");
        }
        $("#table_number").attr("required", true);
        if (isNameRequired) {
          $("#name").attr("required", true);
        }
        if (isNameRequired) {
          $("#phone_number").attr("required", true);
        }
      }
      return false;
    });

    $(document).on("hide.bs.modal", "#cart-modal", function (event) {
      if (orderStorageIds !== null && emptyCartOnModalClose) {
        emptyCart();
      }
    });

    $(document).on("hide.bs.modal", "#payment-split-modal", function (event) {
      $(".modal-backdrop").remove();
    });

    $(document).on("change", "#customer_select", function () {
      const customer_id = parseInt(this.value);
      locationCart.customer_id = customer_id;

      locationCart.products = locationCart.products.map((product) => {
        return {
          ...product,
          discount_percent: 0,
        };
      });

      $(".modal-backdrop").remove();
      getCartModal();
    });

    if (
      !error_message &&
      !splitOrderId &&
      localStorage.getItem("location-cart")
    ) {
      localStorage.removeItem("location-cart");
    }

    if (error_message && localStorage.getItem("location-cart")) {
      if (shop_type === "take-away") {
        locationCart = JSON.parse(localStorage.getItem("location-cart"));
        getCartModal();

        setTimeout(function () {
          $("#pickup_date").val(locationCart.pickup_date);
          $("#pickup_time").val(locationCart.pickup_time);
          $("#address").val(locationCart.take_away_address);
          $("#first_name").val(locationCart.first_name);
          $("#last_name").val(locationCart.last_name);
        }, 500);
      } else if (shop_type === "automat" || shop_type === "pos") {
        locationCart = JSON.parse(localStorage.getItem("location-cart"));
        getCartModal();

        if (shop_type === "automat") {
          setTimeout(function () {
            $("#self-service-table-select-btn").text(
              locationCart.table_number ? locationCart.table_number : "Velg"
            );
          }, 500);
        }
      } else if (shop_type === "dine-in") {
        locationCart = JSON.parse(localStorage.getItem("location-cart"));

        if (localStorage.getItem("saved_table_id")) {
          emptyAllCart();

          if (locationCart.number_of_products == 0) {
            const identifier = localStorage.getItem("saved_table_id");
            const extraTable = "";

            $.ajax({
              type: "POST",
              url: "/ajax/order/get_saved_order_table",
              data: {
                organizer_id: organizerId,
                location_id: locationId,
                table_id: identifier,
                extra_table_id: extraTable,
              },
            })
              .done(function (data) {
                if (
                  data.location_id !== undefined &&
                  data.location_id !== null &&
                  data.location_id == locationId
                ) {
                  $("#order-storage-list-modal").modal("hide");
                  $(".modal-backdrop").remove();
                  if (posType === "classic") {
                    setClassicLocationCart(data);
                  } else {
                    setLocationCart(data);
                  }
                }
              })
              .fail(function (data) {
                if (
                  data.responseJSON.redirect_url !== undefined &&
                  data.responseJSON.redirect_url !== null
                ) {
                  document.location.href = data.responseJSON.redirect_url;
                }
              });
          }
        } else {
          getCartModal();

          setTimeout(function () {
            $("#self-service-table-select-btn").text(locationCart.table_number);
          }, 500);
        }
      } else {
        return;
      }
    }

    if (splitOrderId && localStorage.getItem("location-cart")) {
      locationCart = JSON.parse(localStorage.getItem("location-cart"));
      locationCart.order_id = splitOrderId;
      getPaymentSplitModal(locationCart);
    }

    function checkPaymentStatus(tableId) {
      $.ajax({
        type: "POST",
        url: "/ajax/order/get_saved_order_table_payment_status",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          table_id: tableId,
          extra_table_id: "",
        },
      })
        .done(function (data) {
          if (data && data.length) {
            let allPaid = data.every(function (item) {
              return item.payment_status === 1;
            });

            if (allPaid) {
              localStorage.removeItem("saved_table_id");
            } else {
              let selectedTableNumber = localStorage.getItem("saved_table_id");
              locationCart.table_number = selectedTableNumber;
              $("#self-service-table-select-btn").text(selectedTableNumber);
              $(".save_order_self_service").attr("data-extra-table", "");
              $("#view-saved-table-contanier").removeClass("hidden");
              $("#view-saved-table-contanier").attr(
                "data-table-id",
                selectedTableNumber
              );
              $("#location-cart-summary").addClass("has-saved-table");
              if ($(".space-2").hasClass("d-none")) {
                $(".space-2").removeClass("d-none");
              }
              if (!$(".space-2").hasClass("d-block")) {
                $(".space-2").addClass("d-block");
              }
            }
            return;
          } else {
            return;
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    if (
      localStorage.getItem("saved_table_id") &&
      shop_type === "dine-in" &&
      !error_message
    ) {
      checkPaymentStatus(localStorage.getItem("saved_table_id"));
    }

    var modalTimeout = null;
    $(document).on("click", "#recorder", function (e) {
      $(this).toggleClass("active");
      if ($(this).hasClass("active")) {
        startRecording();
        startOrResetFunction(() => {
          $("#product-suggestion-modal").modal({
            backdrop: "static",
            keyboard: false,
          });

          modalTimeout = setTimeout(() => {
            $("#cancel-suggestion").click();
          }, 5000);
        }, 180000);
      } else {
        stopRecording();
        clearInactivityTimeout();
      }
    });

    $(document).on("click", "#continue-suggestion", function () {
      $("#product-suggestion-modal").modal("hide");
      clearTimeout(modalTimeout);
      startOrResetFunction(() => {
        $("#product-suggestion-modal").modal("show");
        modalTimeout = setTimeout(() => {
          $("#product-suggestion-modal").modal("hide");
        }, 5000);
      }, 180000);
    });

    $(document).on("click", "#cancel-suggestion", function () {
      $("#product-suggestion-modal").modal("hide");
      stopRecording();
      clearInactivityTimeout();
      clearTimeout(modalTimeout);
    });

    // Order Payment Split
    $(document).on("click", ".order-payment-split", function (e) {
      e.preventDefault();
      const siblingInput = $(this).siblings(".payment-split-input");

      if (
        !siblingInput.val() ||
        parseFloat(siblingInput.val().repeat(",", ".")) <= 0
      ) {
        return;
      }

      const orderId = $("#payment-split-modal").attr("data-order-id");
      const button = $(this);
      const buttonText = button.text();
      $("div.loader").remove();
      $(".order-button").attr("disabled", true);
      $(".order-payment-split").attr("disabled", true);

      locationCart.order_storage_identifier = "";
      const paymentMethod = $(this).attr("data-payment-method");
      locationCart.payment_split_method = paymentMethod;
      if (paymentMethod === "payment_terminal") {
        locationCart.terminal_id = $(this).attr("data-terminal-id");
      }

      if (
        locationCart.payment_split_method == "nets_card" ||
        locationCart.payment_split_method == "nets_vipps"
      ) {
        localStorage.setItem("location-cart", JSON.stringify(locationCart));
      }

      if (!order_ajax_lock) {
        button.empty();
        button.append('<div class="loader"></div>');

        order_ajax_lock = true;

        if (orderId) {
          const payload = {
            order_id: orderId,
            amount: siblingInput.val(),
            payment_method: locationCart.payment_split_method,
            terminal_id: locationCart.terminal_id,
            shop_type,
          };

          $.ajax({
            type: "POST",
            url: "/api/v1/private/order/payment-split",
            data: payload,
          })
            .done(function (data) {
              $("div.loader").remove();
              $(".order-button").attr("disabled", false);
              $(".order-payment-split").attr("disabled", false);
              button.attr("disabled", true);
              button.removeClass("btn-primary");
              button.addClass("btn-success");
              button.css("opacity", "1");
              button.append('<i class="material-icons p-0">check_circle</i>');
              siblingInput.attr("disabled", true);

              if (data.order_id !== null && data.order_id !== undefined) {
                $("#payment-split-modal").attr("data-order-id", data.order_id);
              }

              if (
                data.payment_redirect_url !== undefined &&
                data.payment_redirect_url !== null
              ) {
                document.location.href = data.payment_redirect_url;
              }

              const orderHtml =
                data.order_html !== undefined && data.order_html !== null
                  ? data.order_html
                  : "";
              if ($("#content").hasClass("is-employee") && orderHtml !== "") {
                $("#payment-split-modal").modal("hide");
                emptyCart();
                $("#cart-modal").modal("hide");
                $(".modal-backdrop").remove();
                $(".confirmation-toast").remove();
                $("#content").append(
                  '<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                    '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                    '  <div class="toast-header">' +
                    '    <strong class="mr-auto">Ordre registrert</strong>' +
                    "    <small></small>" +
                    '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                    '      <span aria-hidden="true">&times;</span>' +
                    "    </button>" +
                    "  </div>" +
                    '  <div class="toast-body">' +
                    orderHtml +
                    "  </div>" +
                    "</div>" +
                    "</div>"
                );
                $(".modal-backdrop").remove();
                $(".toast").toast("show");
                removeQueryParams("order");
                localStorage.removeItem("location-cart");
              }
              order_ajax_lock = false;
            })
            .fail(function (data) {
              $("div.loader").remove();
              $(".order-button").attr("disabled", false);
              $(".order-payment-split").attr("disabled", false);
              button.text(buttonText);
              errorPopupAlert("Payment fail!");
              if (
                data.responseJSON !== undefined &&
                data.responseJSON.error_message !== undefined &&
                data.responseJSON.error_message !== null
              ) {
                if (!$("#order-button-wrapper").is(":visible")) {
                  alert(data.responseJSON.error_message);
                } else {
                }
              }
              order_ajax_lock = false;
              cartQuickPaymentTerminalLock = false;
            });

          return;
        }

        if ($("#pick-up-at-the-bar").length) {
          locationCart.pick_up_at_the_bar = true;
        }

        let newOrderData = {
          location_id: locationId,
          location_type: locationCart.location_type,
          table_number: locationCart.table_number,
          products: locationCart.products,
          tips_percent: locationCart.tips_percent,
          payment_method: locationCart.payment_method,
          terminal_id: locationCart.terminal_id,
          comment: locationCart.comment,
          name: locationCart.name,
          phone_number: locationCart.phone_number,
          pickup_date: locationCart.pickup_date,
          pickup_time: locationCart.pickup_time,
          promo_code: locationCart.promo_code,
          custom_pickup_time: $("#custom_pickup_time").val(),
          pick_up_at_the_bar: locationCart.pick_up_at_the_bar,
          order_storage_identifier: locationCart.order_storage_identifier,
          userId,
          customer_number: locationCart.customer_id,
          table_customer_id: locationCart.table_customer_id ?? "",
          shop_type,
          split_payment_method: locationCart.payment_split_method,
          split_payment_amount: siblingInput.val(),
        };

        if (orderStorageIds !== null) {
          newOrderData.order_storage_ids = orderStorageIds;
        }

        $.ajax({
          type: "POST",
          url: "/ajax/order/new",
          data: newOrderData,
          timeout: 120000,
        })
          .done(function (data) {
            $("div.loader").remove();
            $(".order-button").attr("disabled", false);
            $(".order-payment-split").attr("disabled", false);
            button.attr("disabled", true);
            button.removeClass("btn-primary");
            button.addClass("btn-success");
            button.css("opacity", "1");
            button.append('<i class="material-icons p-0">check_circle</i>');
            siblingInput.attr("disabled", true);
            if (
              data.payment_redirect_url !== undefined &&
              data.payment_redirect_url !== null
            ) {
              document.location.href = data.payment_redirect_url;
            }

            if (data.order_id !== null && data.order_id !== undefined) {
              $("#payment-split-modal").attr("data-order-id", data.order_id);
            }

            const orderHtml =
              data.order_html !== undefined && data.order_html !== null
                ? data.order_html
                : "";
            if ($("#content").hasClass("is-employee") && orderHtml !== "") {
              $("#payment-split-modal").modal("hide");
              emptyCart();
              $("#cart-modal").modal("hide");
              $(".modal-backdrop").remove();
              $(".confirmation-toast").remove();
              $("#content").append(
                '<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                  '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                  '  <div class="toast-header">' +
                  '    <strong class="mr-auto">Ordre registrert</strong>' +
                  "    <small></small>" +
                  '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                  '      <span aria-hidden="true">&times;</span>' +
                  "    </button>" +
                  "  </div>" +
                  '  <div class="toast-body">' +
                  orderHtml +
                  "  </div>" +
                  "</div>" +
                  "</div>"
              );
              $(".toast").toast("show");
              removeQueryParams("order");
              localStorage.removeItem("location-cart");
            }
            order_ajax_lock = false;
          })
          .fail(function (data) {
            $("div.loader").remove();
            $(".order-button").attr("disabled", false);
            $(".order-payment-split").attr("disabled", false);
            button.text(buttonText);
            errorPopupAlert("Payment fail!");
            if (
              data.responseJSON !== undefined &&
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              if (!$("#order-button-wrapper").is(":visible")) {
                alert(data.responseJSON.error_message);
              } else {
                blinkButton(
                  $(`.terminal-button-${locationCart.terminal_id}`),
                  "red"
                );
                $(".loader").attr("hidden", true);
                prependAlert(
                  "#order-button-wrapper, #terminal-modal-body",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#order_register_form"
                );
              }
            }
            order_ajax_lock = false;
            cartQuickPaymentTerminalLock = false;
          })
          .always(function () {
            locationCart.order_storage_identifier = "";
            if (
              !(
                $("#content").hasClass("is-employee") &&
                (locationCart.payment_method == "payment_terminal" ||
                  locationCart.payment_method == "payment_cash" ||
                  locationCart.payment_method == "payment_method_coupon")
              )
            ) {
              order_ajax_lock = false;
            }
          });
      }
      return false;
    });
  }
});

function appendProductSuggestions(event) {
  const data = JSON.parse(event.data);

  if (!data.html || data.html === "") {
    return;
  }

  let htmlStringToAppend = data.html;
  let existingproductIds = $("#suggested-product div")
    .map(function () {
      return this.id ? this.id : null;
    })
    .get();

  let cartProductIds =
    locationCart.products.length > 0
      ? locationCart.products.map((product) => Number(product.product_id))
      : [];

  let excludeProducts = $("#exclude-products").val();
  let lowerCaseProducts = excludeProducts
    ? excludeProducts.split(",").map((product) => product.trim().toLowerCase())
    : [];

  if (
    existingproductIds.length > 0 ||
    lowerCaseProducts.length > 0 ||
    cartProductIds.length > 0
  ) {
    let filteredProductArray = $(data.html)
      .filter('div[data-product-type="suggested"]')
      .toArray()
      .filter(
        (productEl) =>
          !existingproductIds.includes(productEl.id) &&
          !cartProductIds.includes(Number(productEl.id.split("-")[2])) &&
          !lowerCaseProducts.some((item) =>
            $(productEl)
              .data("product-name")
              .trim()
              .toLowerCase()
              .includes(item)
          )
      );
    htmlStringToAppend = filteredProductArray.reduce(
      (html, ele) => html + ele.outerHTML,
      ""
    );
  }
  if (htmlStringToAppend === "") {
    return;
  }

  $(".suggested-product-list-container").attr("hidden", false);
  $("#remove-suggested-product-container").attr("hidden", false);
  $("#suggested-product").append(htmlStringToAppend);
  adjustProductsHeight();
}

function startRecording() {
  let locationId = $("#location").attr("data-location-id");
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "start_recording",
        locationId: locationId,
      })
    );

    document.addEventListener("message", appendProductSuggestions);
    window.addEventListener("message", appendProductSuggestions);
    return;
  }

  if (!isRecording) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        audioStream = stream;
        mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = function (event) {
          audioChunks.push(event.data);
        };

        mediaRecorder.onstop = function () {
          var audioBlob = new Blob(audioChunks, {
            type: "audio/webm",
          });
          var formData = new FormData();
          formData.append("audio", audioBlob, "audio.webm");
          formData.append("location_id", locationId);
          formData.append("platform", "web");

          $.ajax({
            url: "/api/v1/private/products/speech-search",
            type: "POST",
            data: formData,
            processData: false,
            contentType: false,
            success: function (data) {
              let excludeProducts = $("#exclude-products").val();
              if (!data.html || data.html === "") {
                return;
              }
              let htmlStringToAppend = data.html;
              let existingproductIds = $("#suggested-product div")
                .map(function () {
                  return this.id ? this.id : null;
                })
                .get();

              let cartProductIds =
                locationCart.products.length > 0
                  ? locationCart.products.map((product) =>
                      Number(product.product_id)
                    )
                  : [];

              let lowerCaseProducts = excludeProducts
                ? excludeProducts
                    .split(",")
                    .map((product) => product.trim().toLowerCase())
                : [];

              if (
                existingproductIds.length > 0 ||
                lowerCaseProducts.length > 0 ||
                cartProductIds.length > 0
              ) {
                let filteredProductArray = $(data.html)
                  .filter('div[data-product-type="suggested"]')
                  .toArray()
                  .filter(
                    (productEl) =>
                      !existingproductIds.includes(productEl.id) &&
                      !cartProductIds.includes(
                        Number(productEl.id.split("-")[2])
                      ) &&
                      !lowerCaseProducts.some((item) =>
                        $(productEl)
                          .data("product-name")
                          .trim()
                          .toLowerCase()
                          .includes(item)
                      )
                  );
                htmlStringToAppend = filteredProductArray.reduce(
                  (html, ele) => html + ele.outerHTML,
                  ""
                );
              }
              if (htmlStringToAppend === "") {
                return;
              }

              $(".suggested-product-list-container").attr("hidden", false);
              $("#remove-suggested-product-container").attr("hidden", false);
              $("#suggested-product").append(htmlStringToAppend);
              adjustProductsHeight();
            },
            error: function (err) {
              console.error("Error: ", err);
            },
          });

          audioChunks = [];
          if (isRecording) {
            mediaRecorder.start();
            setTimeout(function () {
              mediaRecorder.stop();
            }, 3000);
          }
        };

        mediaRecorder.start();
        isRecording = true;

        setTimeout(function () {
          mediaRecorder.stop();
        }, 3000);
      })
      .catch(function (err) {
        console.error("Error accessing the microphone: ", err);
      });
  }
}

function stopRecording() {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "stop_recording" })
    );
    return;
  }

  if (isRecording && audioStream) {
    mediaRecorder.stop();
    isRecording = false;
    audioStream.getTracks().forEach((track) => track.stop());
    $("#recorder").removeClass("active");
  }
}

$(document).on("click", "#remove-suggested-product-button", function () {
  $(".suggested-product-list-container").attr("hidden", true);
  $("#suggested-product").empty();
  $("#remove-suggested-product-container").attr("hidden", true);
  adjustProductsHeight();
});

function adjustProductsHeight() {
  var viewportHeight = $(window).height();

  var suggestedHeight = $("#classic-product-category-nav").outerHeight() || 0;

  var availableHeight = viewportHeight - suggestedHeight - 80;

  $(".products").css("max-height", availableHeight + "px");
  $(".products").css("padding-bottom", 3 + "rem");
}

$(window).resize(adjustProductsHeight);
