$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "credit_orders") {
    $("select[name=customer-select]").select2({
      theme: "bootstrap4",
    });

    let event_id = $("body").attr("event_id");
    let orderBy = "date";
    let orderByDirection = "asc";
    let searchString = "";
    let startDate;
    let endDate;
    let customer_id;

    $(document).on("click", "#order-delete-btn", function () {
      const creditOrderId = $(this).attr("data-delete-id");
      $("#delete-credit-order-confirm").attr("data-delete-id", creditOrderId);
    });

    $(document).on("click", "#delete-credit-order-confirm", function () {
      const creditOrderIdToDelete = $(this).attr("data-delete-id");
      searchString = $("#search_string").val();
      startDate = $("#start-date").val();
      endDate = $("#end-date").val();
      customer_id = $("#customer-select").val();

      if (!creditOrderIdToDelete) return;

      $.ajax({
        type: "POST",
        url: "/api/v1/private/credit-order/delete",
        data: {
          order_id: creditOrderIdToDelete,
        },
      })
        .done(function (data) {
          if (data.success) {
            $("#credit-order-list-view").empty();
            $("#credit-order-list-view").html(data.html);
            $("#creditOrderDeleteConfirmationModal").modal("hide");
            popupAlert();
            $(".modal-backdrop").remove();
            getCreditOrder(
              event_id,
              searchString,
              startDate,
              endDate,
              customer_id,
              orderBy,
              orderByDirection
            );
          }
        })
        .fail(function (data) {
          if (data.responseJSON.redirect_url) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (data.error) {
            return alert(data.error);
          }
        });
    });

    $(document).on("submit", "#credit-order-search-form", function (e) {
      e.preventDefault();
      searchString = $("#search_string").val();
      startDate = $("#start-date").val();
      endDate = $("#end-date").val();
      customer_id = $("#customer-select").val();
      getCreditOrder(
        event_id,
        searchString,
        startDate,
        endDate,
        customer_id,
        orderBy,
        orderByDirection
      );
    });

    $(document).on("change", "#start-date", function (e) {
      e.preventDefault();
      searchString = $("#search_string").val();
      startDate = $(this).val();
      endDate = $("#end-date").val();
      customer_id = $("#customer-select").val();
      getCreditOrder(
        event_id,
        searchString,
        startDate,
        endDate,
        customer_id,
        orderBy,
        orderByDirection
      );
    });

    $(document).on("change", "#end-date", function (e) {
      e.preventDefault();
      searchString = $("#search_string").val();
      startDate = $("#start-date").val();
      endDate = $(this).val();
      customer_id = $("#customer-select").val();
      getCreditOrder(
        event_id,
        searchString,
        startDate,
        endDate,
        customer_id,
        orderBy,
        orderByDirection
      );
    });

    $(document).on("change", "#customer-select", function (e) {
      e.preventDefault();
      searchString = $("#search_string").val();
      startDate = $("#start-date").val();
      endDate = $("#end-date").val();
      customer_id = $(this).val();
      getCreditOrder(
        event_id,
        searchString,
        startDate,
        endDate,
        customer_id,
        orderBy,
        orderByDirection
      );
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      searchString = $("#search_string").val();
      startDate = $("#start-date").val();
      endDate = $("#end-date").val();
      customer_id = $("#customer-select").val();
      orderBy = $(this).attr("data-order-by");
      orderByDirection = $(this).attr("data-order-by-direction");
      getCreditOrder(
        event_id,
        searchString,
        startDate,
        endDate,
        customer_id,
        orderBy,
        orderByDirection
      );
    });

    function getCreditOrder(
      event_id,
      searchString = "",
      startDate,
      endDate,
      customer_id = 0,
      orderBy,
      orderByDirection
    ) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/credit-order/search",
        data: {
          event_id,
          search_string: searchString,
          start_date: startDate,
          end_date: endDate,
          customer_id,
          order_by: orderBy,
          order_by_direction: orderByDirection,
        },
      })
        .done(function (data) {
          if (data.html) {
            $("#credit-order-list-view").empty();
            $("#credit-order-list-view").html(data.html);
            const pathName = window.location.pathname;
            let downloadUrl = pathName + "/download?";
            if (customer_id !== 0) {
              downloadUrl += "customer_id=" + customer_id + "&";
            }

            if (startDate !== "") {
              downloadUrl += "start_date=" + startDate + "&";
            }

            if (endDate !== "") {
              downloadUrl += "end_date=" + endDate;
            }

            $("#credit_order_download").attr("href", downloadUrl);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
