$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let locationId = $("body").attr("event_id");
  let isDragging = false;
  let selectedColumnIndex = -1;
  let startRowIndex;
  let selectedCells = [];
  let initialSelectedCell = null;
  let prevSelectedColumnIndex = -1;
  let productIdsToUpdate = [];
  let invalidValueList = [];
  let oldValue = 0;
  if (p === "organizer_event_ticket_category" && a === "list") {
    $(document).on("mousedown", ".editable-data", function (e) {
      e.preventDefault();
      $(
        "select[name=product_group_unique_id],select[name=supplier_id_selector],select[name=mva]"
      ).select2({
        theme: "bootstrap4",
      });
      startDrag(e);
    });

    $(document).on("input", ".drag-update-input", function (e) {
      if ($(this).val()) {
        $("#confirmButton").prop("disabled", false);
      } else {
        $("#confirmButton").prop("disabled", true);
      }
    });

    //enable confirm button
    $(document).on("change", ".drag-update-input", function () {
      if (!$(this).val()) {
        return;
      }
      let firstSelectedCell = selectedCells[0];

      invalidValueList = [];

      $("#confirmButton").prop("disabled", false);
    });

    function startDrag(event) {
      let columnIndex = $(event.target).parent().children().index(event.target);
      let cellType = $(event.target).attr("data-cell-type");
      if (selectedColumnIndex !== columnIndex) {
        resetSelection();
        isDragging = true;
        selectedColumnIndex = columnIndex;
        startRowIndex = $(event.target)
          .parent()
          .parent()
          .children()
          .index($(event.target).parent());
        initialSelectedCell = event.target;
      }

      let currentCell = event.target;
      if (selectedCells.includes(currentCell)) {
        $(currentCell).removeClass("highlight");
        selectedCells.splice(selectedCells.indexOf(currentCell), 1);
      } else {
        if (cellType === "product-sku") {
          // $(".editable-data").removeClass("highlight");
          // selectedCells = [];
          let tooltip = $(currentCell).attr("data-tooltip");

          $(currentCell).attr("data-toggle", "tooltip");
          $(currentCell).attr("data-placement", "left");
          $(currentCell).attr("title", tooltip);
          let tooltipInstance = new bootstrap.Tooltip(currentCell);
          $(currentCell).tooltip("show");

          setTimeout(() => {
            $(currentCell).tooltip("hide");
            tooltipInstance.dispose();
            $(currentCell).removeAttr("data-toggle");
            $(currentCell).removeAttr("data-placement");
            $(currentCell).removeAttr("title");
          }, 2000);
          return;
        }
        $(currentCell).addClass("highlight");
        selectedCells.push(currentCell);
      }

      updateSelectionUI();
      $(document).on("mousemove", drag);
      $(document).on("mouseup", endDrag);
    }

    function drag(event) {
      event.preventDefault();
      let columnIndex = $(event.target).parent().children().index(event.target);
      if (columnIndex !== selectedColumnIndex) {
        resetSelection();
        selectedColumnIndex = columnIndex;
        updateSelectionUI();
      }

      let currentRowIndex = $(event.target)
        .parent()
        .parent()
        .children()
        .index($(event.target).parent());

      let cells = $(
        "#event_ticket_category_table tr:nth-child(n+" +
          (Math.min(startRowIndex, currentRowIndex) + 1) +
          "):nth-child(-n+" +
          (Math.max(startRowIndex, currentRowIndex) + 1) +
          ") td:nth-child(" +
          (selectedColumnIndex + 1) +
          ")"
      );
      cells.each(function () {
        let cellRowIndex = $(this)
          .parent()
          .parent()
          .children()
          .index($(this).parent());
        if (cellRowIndex === currentRowIndex) {
          if (!selectedCells.includes(this)) {
            if ($(this).attr("data-cell-type") !== "product-sku") {
              $(this).addClass("highlight");
              selectedCells.push(this);
            }
          }
        }
      });
    }

    function endDrag() {
      $(document).off("mousemove", drag);
      $(document).off("mouseup", endDrag);
      updateSelectionUI(); // Update UI after dragging ends
      if ($(selectedCells[0]).attr("data-cell-type") !== "product-mva") {
        $("#confirmButton").prop("disabled", true);
      }
      prevSelectedColumnIndex = selectedColumnIndex;
    }

    function resetSelection() {
      for (let cell of selectedCells) {
        $(cell).removeClass("highlight");
      }
      selectedCells = [];
      isDragging = false;
    }

    function updateSelectionUI() {
      let newValueInput = $("#newValueInput");
      let cancelButton = $("#cancelButton");
      let confirmButton = $("#confirmButton");

      if (selectedCells.length > 0) {
        let firstSelectedCell = selectedCells[0];
        let isSelectable = $(firstSelectedCell).attr("data-selectable");
        let currentSelectedCellTpye =
          $(firstSelectedCell).attr("data-cell-type");
        if (isSelectable) {
          if (currentSelectedCellTpye == "product-supplier") {
            $("#supplier-select").css("display", "inline-block");
            $("#supplier-select").focus();
            $("#mva-select").css("display", "none");
            $("#product-group-unique-select").css("display", "none");
          }

          if (currentSelectedCellTpye == "product-group-unique") {
            $("#product-group-unique-select").css("display", "inline-block");
            $("#product-group-unique-select").focus();
            $("#mva-select").css("display", "none");
            $("#supplier-select").css("display", "none");
          }

          if (currentSelectedCellTpye == "product-mva") {
            $("#confirmButton").removeAttr("disabled");
            $("#mva-select").css("display", "inline-block");
            $("#mva-select").focus();
            $("#supplier-select").css("display", "none");
            $("#product-group-unique-select").css("display", "none");
          }

          $("#textInput").css("display", "none");
          $("#numberInput").css("display", "none");
        } else if (currentSelectedCellTpye == "product-name") {
          $("#supplier-select").css("display", "none");
          $("#mva-select").css("display", "none");
          $("#numberInput").css("display", "none");
          $("#product-group-unique-select").css("display", "none");
          $("#textInput").css("display", "inline-block");
          $("#textInput").focus();
        } else if (currentSelectedCellTpye == "product-sku") {
          $("#supplier-select").css("display", "none");
          $("#mva-select").css("display", "none");
          $("#numberInput").css("display", "none");
          $("#product-group-unique-select").css("display", "none");
          $("#textInput").css("display", "inline-block");
          $("#textInput").focus();
        } else {
          $("#supplier-select").css("display", "none");
          $("#mva-select").css("display", "none");
          $("#textInput").css("display", "none");
          $("#product-group-unique-select").css("display", "none");
          $("#numberInput").css("display", "inline-block");
          $("#numberInput").focus();
        }
        newValueInput.css("display", "flex");
        cancelButton.css("display", "inline-block");
        confirmButton.css("display", "inline-block");
      } else {
        newValueInput.css("display", "none");
        cancelButton.css("display", "none");
        confirmButton.css("display", "none");
      }
    }

    $(document).on("click", "#cancelButton", function cancelSelection(e) {
      e.preventDefault();
      $("#textInput").val("");
      $("#numberInput").val(0);
      $("#selectInput").val("male");
      $("#confirmButton").prop("disabled", true);
      invalidValueList = [];
      resetSelection();
      updateSelectionUI();
    });

    $(document).on("click", "#confirmButton", function () {
      let newValue = null;
      let firstSelectedCell = selectedCells[0];
      let columnToUpdate = $(firstSelectedCell).attr("data-cell-type");
      let isSelectable = $(firstSelectedCell).attr("data-selectable");
      if (isSelectable) {
        if (columnToUpdate == "product-supplier") {
          newValue = $(
            "select[name='supplier_id_selector'] option:selected"
          ).val();
        }
        if (columnToUpdate == "product-mva") {
          newValue = $("select[name='mva'] option:selected").val();
        }
        if (columnToUpdate == "product-group-unique") {
          newValue = $(
            "select[name='product_group_unique_id_drag_edit'] option:selected"
          ).val();
        }
      } else if (columnToUpdate == "product-name") {
        newValue = $("#textInput").val();
      } else if (columnToUpdate == "product-sku") {
        newValue = $("#textInput").val();
        let selectedSKUColumn = selectedCells[0];
        oldValue = $(selectedSKUColumn).attr("data-old-value");
      } else {
        let inputedValue = $("#numberInput").val().replace(",", ".");
        if (isNaN(inputedValue)) {
          return alert("Verdien må være et tall");
        } else {
          newValue = inputedValue;
        }
      }

      if (invalidValueList.length > 0) {
        let alertMessage = "";
        $.each(invalidValueList, function (index, item) {
          alertMessage +=
            item.error + " on Product: " + item.productName + "\n";
        });
        $("#numberInput").focus();
        return alert(alertMessage);
      }
      for (let cell of selectedCells) {
        productIdsToUpdate.push(
          $(cell).parent().attr("data-event-ticket-category-id")
        );
      }

      $("#textInput").val(""); // Clear text input field
      $("#numberInput").val(0); // Clear number input field
      $("#supplier-select").val("0"); // Reset select input field
      $("#mva-select").val("0"); // Reset select input field
      $("#confirmButton").prop("disabled", true);
      resetSelection();
      updateSelectionUI();
      updateProductDragSelected(
        productIdsToUpdate,
        columnToUpdate,
        newValue,
        locationId,
        oldValue
      );
      productIdsToUpdate = [];
    });
  }

  function updateProductDragSelected(
    productIds = [],
    columnToUpdate,
    newValue,
    locationId,
    oldValue = 0
  ) {
    $.ajax({
      type: "POST",
      url: "/ajax/organizer_event_ticket_category/update_product_drag_selected",
      data: {
        productIds,
        columnToUpdate,
        newValue,
        locationId,
        oldValue,
      },
    })
      .done(function (data) {
        if (data.error) {
          alert(data.error);
          return;
        }
        popupAlert();

        invalidValueList = [];

        getProductList();
        return true;
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }

  function getProductList() {
    let live_edit = 0;
    let search_string = $("#search_string").val();
    let product_type = $("#product_type").val();
    let supplier_id = $("#sort").data("supplier-id");
    let page = $("#event_ticket_category_table").attr("data-page");

    let formData = new FormData();
    formData.append("live_edit", live_edit);
    formData.append("search_string", search_string);
    formData.append("location_id", locationId);
    formData.append("product_type", product_type);
    formData.append("supplier_id", supplier_id);
    formData.append("page", page);
    formData.append("status", 1);
    $.ajax({
      type: "POST",
      url: "/ajax/organizer_event_ticket_category/search",
      data: formData,
      enctype: "multipart/form-data",
      async: true,
      cache: false,
      processData: false,
      contentType: false,
    })
      .done(function (data) {
        if (data.html !== undefined && data.html !== null) {
          $("#product-list").empty();
          $("#product-list").append(data.html);
        }

        $("html,body").scrollTop(0);
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }
});
