$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery =
    $("body").attr("start_date")?.replace("T", " ") || null;
  let endDateFromQuery = $("body").attr("end_date")?.replace("T", " ") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;

  if (p === "statistic_product") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );
    let orderBy = "name";
    let orderByDirection = "desc";
    let isMobile = false;
    let isTablet = false;
    let isDesktop = false;
    var deviceWidth = $(window).width();
    let customerId = 0;

    $(document).on("change", "#customer-id", function (e) {
      e.preventDefault();

      customerId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      orderBy = $(this).attr("data-order-by");
      orderByDirection = $(this).attr("data-order-by-direction");
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });
    $(document).on("change", "#custom-period", function (e) {
      e.preventDefault();
      customPeriod = $(this).val();

      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#top-product-group-id", function (e) {
      e.preventDefault();
      $("#product-group-id").val(0);
      $("select[name=product-group-id]").select2({
        theme: "bootstrap4",
      });
      topProductGroupUniqueId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#supplier-id", function (e) {
      e.preventDefault();
      supplierId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#product-group-id", function (e) {
      e.preventDefault();
      $("#top-product-group-id").val(0);
      $("select[name=top-product-group-id]").select2({
        theme: "bootstrap4",
      });
      productGroupUniqueId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#product-id", function (e) {
      e.preventDefault();
      productId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("change", "#user-id", function (e) {
      e.preventDefault();
      userId = $(this).val();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });
    $(document).on("change", "#platform", function (e) {
      e.preventDefault();
      platform = $(this).val();

      if (platform === "self-service") {
        userId = 0;
        $("#user-id").val(userId);
        $("#user-id").prop("disabled", true);
      } else {
        $("#user-id").prop("disabled", false);
      }
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });
    $(document).on("change", 'input[type="radio"]', function (e) {
      period = $('input[type="radio"]:checked').val();
      prevSelectedPeriod = period !== "custom" ? period : prevSelectedPeriod;

      if (period === "day") {
        startDate = moment().format("YYYY-MM-DD") + " 00:00";
        endDate = moment().format("YYYY-MM-DD") + " 23:59";
        const currentDayFormatted = moment().format("ddd - DD.MM.YYYY");
        customPeriod = "time";
        $("#period-title").text(dayFormatAbbreviation(currentDayFormatted));
        if (compareBy && compareBy != 0) {
          compareBy = "day-compare";
          $("#compare").val("day-compare").trigger("change.select2");
        }
      } else if (period === "week") {
        startDate = moment().startOf("week").format("YYYY-MM-DD") + " 00:00";
        endDate = moment().endOf("week").format("YYYY-MM-DD") + " 23:59";
        const currentWeekNumber = moment().utc().isoWeek();
        customPeriod = "day";
        $(`#custom-period option[value="day"]`).prop("selected", true);
        $("#period-title").text("Uke nr" + " " + currentWeekNumber);
        if (compareBy && compareBy !== "0") {
          compareBy = "week-compare";
          $("#compare").val("week-compare").trigger("change.select2");
        }
      } else if (period === "month") {
        startDate = moment().startOf("month").format("YYYY-MM-DD") + " 00:00";
        endDate = moment().endOf("month").format("YYYY-MM-DD") + " 23:59";
        durationOfstartAndEndDate = 30;

        displayGraphDynamically(durationOfstartAndEndDate);
        if (isDesktop) {
          customPeriod = "day";
          $(`#custom-period option[value="time"]`).prop("selected", false);
          $(`#custom-period option[value="day"]`).prop("selected", true);
        } else {
          customPeriod = "week";
          $(`#custom-period option[value="day"]`).prop("selected", false);
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $(`#custom-period option[value="day"]`).prop("disabled", true);
        }
        const currentMonth = moment().format("MMMM");
        $("#period-title").text(currentMonth);
        if (compareBy && compareBy !== "0") {
          compareBy = "month-compare";
          $("#compare").val("month-compare").trigger("change.select2");
        }
      } else {
        $("#period-title").text("Custom");
        compareBy = null;
        $("#compare").val("0").trigger("change.select2");
        if (startDate == endDate) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period").prop("disabled", false);
        }
      }
      customPeriod = $("#custom-period").val();
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      toggleCustomDatePicker();
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    function getDateAndTimeChange() {
      $("#start-date").datetimepicker({
        dateFormat: "yy-mm-dd",
        timeFormat: "HH:mm",
        showButtonPanel: true,
        regional: ["no", "NO"],
        onClose: function () {
          if (startDate !== $(this).val()) {
            let choosenDate = $("#start-date").val();

            if (dateAndPeriodValidation(choosenDate, endDate)) {
              startDate = choosenDate;
              isDateChanged = true;
              period = $('input[name="period"]:checked').val();
              if (startDate == endDate) {
                $("#custom-period option[value='time']").prop("selected", true);
                $("#custom-period").prop("disabled", true);
              } else {
                durationOfstartAndEndDate = moment
                  .utc(endDate.split(" ")[0])
                  .diff(moment(startDate), "days");
                displayGraphDynamically(durationOfstartAndEndDate);
                $("#custom-period").prop("disabled", false);
                $("#custom-period option[value='time']").prop("disabled", true);
              }
              getOrderedProductQuantity(
                startDate,
                endDate,
                period,
                supplierId,
                productGroupUniqueId,
                locationId,
                userId,
                platform,
                orderBy,
                orderByDirection,
                customPeriod,
                customerId,
                topProductGroupUniqueId,
                compareBy,
                productId
              );
              prevSelectedPeriod = "custom";
              $("#period-title").text(
                moment(startDate).format("DD-MM-YYYY") +
                  " : " +
                  moment(endDate).format("DD-MM-YYYY")
              );
            }
          }
        },
      });

      // Initialize the Datepicker for end-date
      $("#end-date").datetimepicker({
        dateFormat: "yy-mm-dd",
        timeFormat: "HH:mm",
        showButtonPanel: true,
        onClose: function () {
          if (endDate !== $(this).val()) {
            let currentendDate = $("#end-date").val();
            if (dateAndPeriodValidation(startDate, currentendDate)) {
              isDateChanged = true;
              endDate = currentendDate;
              period = $('input[name="period"]:checked').val();
              if (startDate.split(" ")[0] == endDate.split(" ")[0]) {
                $("#custom-period option[value='time']").prop("selected", true);
                $("#custom-period").prop("disabled", true);
              } else {
                durationOfstartAndEndDate = moment
                  .utc(endDate)
                  .diff(moment(startDate.split(" ")[0]), "days");
                displayGraphDynamically(durationOfstartAndEndDate);

                $("#custom-period").prop("disabled", false);
                $("#custom-period option[value='time']").prop("disabled", true);
              }
              getOrderedProductQuantity(
                startDate,
                endDate,
                period,
                supplierId,
                productGroupUniqueId,
                locationId,
                userId,
                platform,
                orderBy,
                orderByDirection,
                customPeriod,
                customerId,
                topProductGroupUniqueId,
                compareBy,
                productId
              );
              prevSelectedPeriod = "custom";
              $("#period-title").text(
                moment(startDate).format("DD-MM-YYYY") +
                  " : " +
                  moment(endDate).format("DD-MM-YYYY")
              );
            }
          }
        },
      });
    }

    $(document).on("click", "#previous-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "day").format("YYYY-MM-DD") +
          " 00:00";
        endDate =
          moment.utc(endDate).subtract(1, "day").format("YYYY-MM-DD") +
          " 23:59";
        periodTitle = dayFormatAbbreviation(
          moment(startDate).format("ddd - DD.MM.YYYY")
        );
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "week").format("YYYY-MM-DD") +
          " 00:00";
        endDate =
          moment(endDate).subtract(1, "week").format("YYYY-MM-DD") + " 23:59";
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "month").format("YYYY-MM-DD") +
          " 00:00";
        endDate =
          moment(endDate).subtract(1, "month").format("YYYY-MM-DD") + " 23:59";
        periodTitle = moment(startDate).format("MMMM");
      }
      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        endDate =
          moment(startDate).subtract(1, "day").format("YYYY-MM-DD") + " 23:59";

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        startDate =
          moment
            .utc(endDate)
            .subtract(durationOfstartAndEndDate, "days")
            .format("YYYY-MM-DD") + " 00:00";
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    $(document).on("click", "#next-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).add(1, "day").format("YYYY-MM-DD") + " 00:00";
        endDate =
          moment.utc(endDate).add(1, "day").format("YYYY-MM-DD") + " 23:59";
        periodTitle = dayFormatAbbreviation(
          moment(startDate).format("ddd - DD.MM.YYYY")
        );
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment(startDate).add(1, "week").format("YYYY-MM-DD") + " 00:00";
        endDate =
          moment(endDate).add(1, "week").format("YYYY-MM-DD") + " 23:59";
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate =
          moment(startDate).add(1, "month").format("YYYY-MM-DD") + " 00:00";
        endDate =
          moment(endDate).add(1, "month").format("YYYY-MM-DD") + " 23:59";
        periodTitle = moment(startDate).format("MMMM");
      }

      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        startDate =
          moment(endDate).add(1, "day").format("YYYY-MM-DD") + " 00:00";

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        endDate =
          moment
            .utc(startDate)
            .add(durationOfstartAndEndDate, "days")
            .format("YYYY-MM-DD") + " 23:59";
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      getOrderedProductQuantity(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        orderBy,
        orderByDirection,
        customPeriod,
        customerId,
        topProductGroupUniqueId,
        compareBy,
        productId
      );
    });

    function eventLineChart(
      categoryArray,
      seriesData1,
      seriesData2,
      seriesData3,
      interval = null
    ) {
      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: {
          type: "column",
        },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 4, // Adjust line width as needed
          },
        },

        xAxis: {
          categories: categoryArray,
          labels: {
            formatter: function () {
              if (startDate.split(" ")[0] === endDate.split(" ")[0]) {
                return this.value;
              } else if (customPeriod === "week") {
                return "Uke " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat(
                  "%B",
                  new Date(this.value).getTime()
                );
              } else {
                return Highcharts.dateFormat(
                  "%e/%b",
                  new Date(this.value).getTime()
                );
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          title: {
            text: "Antall varer",
          },
        },
        series:
          seriesData2.length === 0 && seriesData3.length === 0
            ? [
                {
                  name: "Antall varer",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ]
            : [
                {
                  name: "Antall varer",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Antall varer forrige uke",
                  data: seriesData2.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Antall varer forrige år",
                  data: seriesData3.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ],
        tooltip: {
          formatter: function () {
            var tooltipText;
            if (startDate.split(" ")[0] === endDate.split(" ")[0]) {
              // Default formatting for datetime xAxis
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", this.x) +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                this.x +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", this.x) +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", this.x) +
                "</b><br/>" +
                "Antall varer : " +
                formatNumber(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }
    $(document).on("change", "#compare", function (e) {
      e.preventDefault();
      compareBy = $(this).val();
      if (compareBy === "day-compare") {
        period = "day";
        $("#period-day").prop("checked", true);
      } else if (compareBy === "week-compare") {
        period = "week";
        $("#period-week").prop("checked", true);
      } else if (compareBy === "month-compare") {
        period = "month";
        $("#period-month").prop("checked", true);
      } else {
        compareBy = null;
      }
      $('input[type="radio"]:checked').trigger("change");
    });

    function getOrderedProductQuantity(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      orderBy,
      orderByDirection,
      customPeriod,
      customerId,
      topProductGroupUniqueId = 0,
      compareBy = null,
      productId = 0
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_product/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          orderBy,
          orderByDirection,
          customPeriod,
          customerId,
          topProductGroupUniqueId,
          compareBy,
          productId,
        },
      })
        .done(function (data) {
          if (data) {
            if (data.totalQuantity) {
              $("#key-figure-text").text(data.totalQuantity);
            } else {
              $("#key-figure-text").text(0);
            }
            if (
              data.xAxisArray.length > 0 &&
              data.seriesData1 &&
              data.seriesData2 &&
              data.seriesData3
            ) {
              eventLineChart(
                data.xAxisArray,
                data.seriesData1,
                data.seriesData2,
                data.seriesData3
              );
            }
            if (data.html) {
              $("#product-list-table").empty;
              $("#product-list-table").html(data.html);
            }
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }
    getOrderedProductQuantity(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      orderBy,
      orderByDirection,
      customPeriod,
      customerId,
      topProductGroupUniqueId,
      compareBy,
      productId
    );
    toggleCustomDatePicker();
    getDateAndTimeChange();
  }
});
