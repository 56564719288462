$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let order = $("body").attr("order") || null;
  const confirmation_code = $("body").attr("confirmation_code");

  if (p === "order_confirmation") {
    let isPOS = new URLSearchParams(window.location.search).get("shop_type")
      ? true
      : false;

    const routeToPOS = $("#route-to-pos").attr("href");

    if (isPOS && !order) {
      setTimeout(function () {
        document.location.href = routeToPOS;
      }, 10000);
    }

    $(document).on("click", "#complete-payment", function () {
      const payload = {
        confirmation_code,
      };

      getPaymentSplitModal(payload, "#order-confirmation-footer");
    });

    $(document).on("click", ".order-payment-split", function (e) {
      e.preventDefault();
      const payload = {};
      const siblingInput = $(this).siblings(".payment-split-input");

      if (!siblingInput.val() || parseFloat(siblingInput.val()) <= 0) {
        return;
      }

      const orderId = $("#payment-split-modal").attr("data-order-id");
      const button = $(this);
      const buttonText = button.text();
      $("div.loader").remove();
      $(".order-button").attr("disabled", true);
      $(".order-payment-split").attr("disabled", true);

      locationCart.order_storage_identifier = "";
      const paymentMethod = $(this).attr("data-payment-method");
      if (paymentMethod === "payment_terminal") {
        payload.terminal_id = $(this).attr("data-terminal-id");
      }

      button.empty();
      button.append('<div class="loader"></div>');

      if (orderId) {
        payload.order_id = orderId;
        payload.amount = siblingInput.val();
        payload.payment_method = paymentMethod;

        $.ajax({
          type: "POST",
          url: "/api/v1/private/order/payment-split",
          data: payload,
        })
          .done(function (data) {
            $("div.loader").remove();
            $(".order-button").attr("disabled", false);
            $(".order-payment-split").attr("disabled", false);
            button.attr("disabled", true);
            button.removeClass("btn-primary");
            button.addClass("btn-success");
            button.css("opacity", "1");
            button.append('<i class="material-icons p-0">check_circle</i>');
            siblingInput.attr("disabled", true);

            if (data.order_id !== null && data.order_id !== undefined) {
              $("#payment-split-modal").attr("data-order-id", data.order_id);
            }
          })
          .fail(function (data) {
            $("div.loader").remove();
            $(".order-button").attr("disabled", false);
            $(".order-payment-split").attr("disabled", false);
            button.text(buttonText);
            errorPopupAlert("Payment fail!");
            if (
              data.responseJSON !== undefined &&
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              if (!$("#order-button-wrapper").is(":visible")) {
                alert(data.responseJSON.error_message);
              } else {
              }
            }
            order_ajax_lock = false;
            cartQuickPaymentTerminalLock = false;
          });

        return;
      }
    });
  }
});
