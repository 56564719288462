$(document).ready(function () {
  let p = $("body").attr("p");
  let locationId = $("body").attr("event_id");
  let trunOverExMva = 0;
  let yesterdayExVatTurnoverSum = 0;
  let isOpening = true;
  const isTablet = !!window.ReactNativeWebView;

  moment.tz.setDefault("Europe/Oslo");
  if (p === "organizer_location_dashboard") {
    function fitToViewport() {
      $("#content").height($(window).height());
    }

    fitToViewport();

    // Adjust on window resize
    $(window).resize(function () {
      fitToViewport();
    });
    moment.updateLocale("en", {
      week: {
        dow: 1, // Monday is the start of the week
      },
      weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
    });
    let start_date = moment().format("YYYY-MM-DD") + "T00:00";
    let end_Date = moment().format("YYYY-MM-DD") + "T23:59";
    let isIncludingTableTurnOver = 0;
    let isIncludingCreditOrder = 0;
    let weeks = 1;
    let months = 1;
    $(document).on("click", "#location-dashboard-turnover-sum", function (e) {
      e.preventDefault();

      window.location.href = `/location/${locationId}/statistics?filter_by=day&custom_period=day&prev_selected_period=day&start_date=${start_date}&end_date=${end_Date}`;
    });
    const currentDay = moment().day();

    // Calculate the day of the week for last week
    const currentDayName = moment().day(currentDay).format("dddd");

    function pieChart(container, primalData, title) {
      const tooltipLabelText =
        container == "location-dashboard-pie-vat" ? "varer" : "ordrer";

      Highcharts.chart(container, {
        chart: {
          type: "variablepie",
          height: isTablet ? 80 : 100,
          style: {
            width: "100%",
          },
          marginLeft: -120,
          marginRight: 5,
        },

        title: false,
        exporting: { enabled: false },
        credits: { enabled: false },
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            "Sum(eks mva): <b>{formattedY}</b><br/>" +
            "Antall " +
            tooltipLabelText +
            ": <b>{point.z}</b><br/>",
          formatter: function () {
            const formattedY = formattedPriceToShow(this.y);
            return (
              '<span style="color:' +
              this.point.color +
              '">\u25CF</span> <b>' +
              this.point.name +
              "</b><br/>" +
              "Sum(esk mva): <b>" +
              formattedY +
              "</b><br/>" +
              "Antall " +
              tooltipLabelText +
              ": <b>" +
              this.point.z +
              "</b><br/>"
            );
          },
          style: {
            fontSize: "11px",
            width: 100,
          },
          backgroundColor: "rgba(255, 255, 255, 1)", // Solid white background
          borderRadius: 5, // Rounded corners
          borderWidth: 1, // Add border for better visibility
          borderColor: "#ccc", // Light gray border
          shadow: true, // Add shadow for depth
          zIndex: 10,
          positioner: function (labelWidth, labelHeight) {
            const chart = this.chart;
            const centerX = chart.plotLeft + chart.series[0].center[0]; // X-center of the pie
            const centerY = chart.plotTop + chart.series[0].center[1]; // Y-center of the pie
            const radius = chart.series[0].center[2] / 2; // Radius of the pie chart

            const tooltipX = centerX + radius + 3; // Place it to the right, just outside the pie
            const tooltipY = centerY - labelHeight / 2; // Center it vertically with the pie
            return { x: tooltipX, y: tooltipY };
          },
          borderWidth: 0,
        },
        plotOptions: {
          variablepie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            enableMouseTracking: true,
            point: {
              events: {
                mouseOver: function () {
                  // Reduce opacity of the corresponding legend item
                  const legendItems = this.series.chart.legend.allItems;
                  legendItems.forEach((item) => {
                    item.legendItem.element.style.opacity = 0.1;
                    item.legendItem.element.style.transition = "opacity 0.5s";
                  });
                },
                mouseOut: function () {
                  // Restore opacity when the mouse leaves
                  const legendItems = this.series.chart.legend.allItems;
                  legendItems.forEach((item) => {
                    item.legendItem.element.style.transition = "opacity 1s";
                    item.legendItem.element.style.opacity = 1;
                  });
                },
              },
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "20%",
            zMin: 0,
            name: "countries",
            data: primalData,
          },
        ],
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          floating: false,
          useHTML: true,
          labelFormatter: function () {
            return `
            <div style="text-align: left; width: 100px; word-wrap: break-word; white-space: normal;">
            ${this.name}
           </div>`;
          },
        },
      });
    }

    function eventBarChart(primalData) {
      Highcharts.chart("event-bar-graph", {
        chart: { type: "column", height: isTablet ? 150 : 200 },
        exporting: { enabled: false },
        title: false,
        xAxis: {
          categories: primalData.date,
          title: {
            text: $("#admin-dashboard-bar-graph").attr("data-x-axis-text"),
          },
        },
        yAxis: {
          title: {
            text: $("#admin-dashboard-bar-graph").attr("data-y-axis-text"),
          },
        },
        credits: { enabled: false },
        series: [
          {
            showInLegend: false,
            data: isOpening ? primalData.revenue : primalData.yesterdayrevenue,
            name: "Omsetning ink. mva.",
          },
        ],
        tooltip: {
          pointFormatter: function () {
            const formattedValue = formattedPriceToShow(this.y);
            return `${this.series.name}: <b>${formattedValue}</b><br/>`;
          },
          shared: true,
        },
      });
    }

    function eventLineChart(currentData) {
      Highcharts.chart("event-line-graph", {
        chart: { height: isTablet ? 150 : 200, marginBottom: 70 },
        exporting: { enabled: false },
        title: false,

        plotOptions: {
          series: {},
        },
        xAxis: {
          categories: currentData.date,
          labels: {
            rotation: 0, // Setting X-axis label rotation to 0 degrees
          },
        },
        yAxis: {
          title: {
            text: $("#admin-dashboard-line-graph").attr("data-y-axis-text"),
          },
        },
        credits: { enabled: false },
        series: [
          {
            name: isOpening ? "Omsetning i dag." : "Omsetning i går",
            data: isOpening
              ? currentData.revenue
              : currentData.yesterdayrevenue,
            color: "#2EADF4",
          },
          {
            name: `Omsetning forrige ${currentDayName} (NOK ${formattedPriceToShow(floor(currentData.lastWeekDaterevenue.reduce((acc,curr) => acc + curr , 0)))} inkl mva)`,
            data: currentData.lastWeekDaterevenue,
            color: "#D3D3D3",
          },
        ],
        tooltip: {
          pointFormatter: function () {
            const formattedValue = formattedPriceToShow(this.y);
            return `${this.series.name}: <b>${formattedValue}</b><br/>`;
          },
          shared: true,
        },
      });
    }

    function columnChart(
      currentWeeklyTotalTurnOver,
      previousWeeklyTotalTurnOver,
      avrageWeeklyTotalTurnOver,
      currentMonthTotalTurnover,
      previousMonthTotalTurnover,
      averageMonthTotalTurnover
    ) {
      let biggestValue = Math.max(
        currentWeeklyTotalTurnOver,
        previousWeeklyTotalTurnOver,
        avrageWeeklyTotalTurnOver,
        currentMonthTotalTurnover,
        previousMonthTotalTurnover,
        averageMonthTotalTurnover
      );

      let firstDateOfMonth =
        moment().startOf("month").format("YYYY-MM-DD") + "T00:00";
      let lastDateOfMonth =
        moment().endOf("month").format("YYYY-MM-DD") + "T23:59";
      let firstDateOfWeek =
        moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
      let lastDateOfWeek =
        moment().endOf("week").format("YYYY-MM-DD") + "T23:59";

      Highcharts.chart("bar-chart", {
        chart: { type: "column", height: 150 },
        exporting: { enabled: false },
        title: false,
        xAxis: {
          categories: ["UKE", "MND"],
          title: {
            text: $("#admin-dashboard-bar-graph").attr("data-x-axis-text"),
          },
        },
        yAxis: {
          min: 0,
          max: biggestValue,
          endOnTick: false,
          title: false,
          labels: {
            formatter: function () {
              if (this.value >= 1000) {
                return (this.value / 1000).toFixed(0) + "k"; // Convert to 'k' notation if value is >= 1000
              }
              return this.value; // Otherwise, return the original value
            },
          },
        },
        credits: { enabled: false },
        plotOptions: {
          column: {
            pointWidth: 20,
            events: {
              click: function (event) {
                // Access the clicked column data here
                let clickedColumnData =
                  event.point.series.data[event.point.index];
                // Redirect to the "/statistic" route
                window.location.href = `/location/${locationId}/statistics?filter_by=${
                  clickedColumnData.category == "UKE" ? "week" : "month"
                }&custom_period="day"&prev_selected_period=${
                  clickedColumnData.category == "UKE" ? "week" : "month"
                }&start_date=${
                  clickedColumnData.category == "UKE"
                    ? firstDateOfWeek
                    : firstDateOfMonth
                }&end_date=${
                  clickedColumnData.category == "UKE"
                    ? lastDateOfWeek
                    : lastDateOfMonth
                }`;
              },
            },
          },
        },

        series: [
          {
            showInLegend: false,
            data: [currentWeeklyTotalTurnOver, currentMonthTotalTurnover],
            name: "Denne",
          },
          {
            showInLegend: false,
            data: [previousWeeklyTotalTurnOver, previousMonthTotalTurnover],
            name: "Forrige",
          },
          {
            showInLegend: false,
            data: [avrageWeeklyTotalTurnOver, averageMonthTotalTurnover],
            name: "Gjennomsnitts",
          },
        ],
        tooltip: {
          formatter: function () {
            if (this.series.name === "Gjennomsnitts") {
              return (
                (this.point.category === "UKE"
                  ? `Siste ${weeks} uker gjennomsnitt : `
                  : `Siste ${months} måneder gjennomsnitt : `) +
                formattedPriceToShow(this.y)
              );
            } else {
              return (
                this.series.name +
                " " +
                (this.point.category === "UKE" ? "uke" : "måned") +
                " : " +
                formattedPriceToShow(this.y)
              );
            }
          },
        },
      });
    }

    $(document).on("show.bs.collapse", "#checkboxContainer", function () {
      $('[data-target="#checkboxContainer"] .rotate-icon').addClass("rotated");
    });
    $(document).on("hide.bs.collapse", "#checkboxContainer", function () {
      $('[data-target="#checkboxContainer"] .rotate-icon').removeClass(
        "rotated"
      );
    });

    $(document).on("change", "#include_table_turnover", function () {
      isIncludingTableTurnOver = $(this).is(":checked") ? 1 : 0;
      ajaxGetStatisticsData();
    });
    $(document).on("change", "#included_credit_order", function () {
      isIncludingCreditOrder = $(this).is(":checked") ? 1 : 0;
      ajaxGetStatisticsData();
    });
    function ajaxGetStatisticsData() {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_location_dashboard/get_statistic",
        data: {
          location_id: $("body").attr("event_id"),
          isIncludingTableTurnOver,
          isIncludingCreditOrder,
        },
      })
        .done(function (data) {
          let currentTime = moment().valueOf();
          let currentWeeklyTotalTurnOver = 0;
          let previousWeeklyTotalTurnOver = 0;
          let avrageWeeklyTotalTurnOver = 0;
          let currentMonthTotalTurnover = 0;
          let previousMonthTotalTurnover = 0;
          let averageMonthTotalTurnover = 0;
          trunOverExMva = 0;
          if (data) {
            const openingTime = moment(
              data.start_date,
              "YYYY-MM-DD HH:mm:ss"
            ).valueOf();

            isOpening = currentTime > openingTime;
          }

          if(parseFloat(data.ex_vat_turnover_sum) > 0){
            trunOverExMva = parseFloat(data.ex_vat_turnover_sum);
          }
          
          if(parseFloat(data.yesterday_ex_vat_turnover_sum) > 0){
            yesterdayExVatTurnoverSum = parseFloat(data.yesterday_ex_vat_turnover_sum);
          }

          if (data && data.order_list) {
            currentWeeklyTotalTurnOver = currentWeekTrunover(
              data.order_list,
              data
            );
            previousWeeklyTotalTurnOver = previousWeekTurnover(
              data.order_list,
              data
            );
            avrageWeeklyTotalTurnOver = averageWeeklyTurnover(
              data.order_list,
              data
            );
            currentMonthTotalTurnover = currentMonthTurnover(
              data.order_list,
              data
            );
            previousMonthTotalTurnover = previousMonthTurnover(
              data.order_list,
              data
            );
            averageMonthTotalTurnover = averageMonthlyTurnover(
              data.order_list,
              data
            );
          }
          let formatedTurnover = formattedPriceToShow(data.turnover_sum);

          //daily turnover
          if (!isOpening) {
            $("#turnover-title").text("Omsetning i går").fadeIn(400);
          }
          $("#location-dashboard-turnover-sum #turnover-title-period")
            .text(isOpening ? data.period_label : data.yesterday_period_label)
            .fadeIn(400);
          $("#location-dashboard-turnover-sum #turnover-sum")
            .hide()
            .text(
              (isOpening
                ? formatedTurnover
                : data.yesterday_turnover_sum
              ).toString().replace(".", ",")
            )
            .fadeIn(400);

          $("#location-dashboard-turnover-sum #turnover-sum-ex-vat")
            .hide()
            .html(
              formattedPriceToShow(
                isOpening
                  ? trunOverExMva +
                      (isIncludingTableTurnOver
                        ? data.turnover_ex_vat_from_prodcut_table
                        : 0)
                  : yesterdayExVatTurnoverSum
              ) +
                " " +
                "eks mva"
            )
            .fadeIn(400);

          pieChart(
            "location-dashboard-pie-vat",
            isOpening ? data.pie_vat_data : data.pie_vat_data_of_yesterday,
            data.revenue_sum_formatted
          );
          pieChart(
            "location-dashboard-pie-payment-option",
            isOpening
              ? data.pie_payment_option_data
              : data.pie_payment_option_data_of_yesterday,
            data.revenue_sum_formatted
          );
          eventBarChart(data);
          eventLineChart(data);
          columnChart(
            currentWeeklyTotalTurnOver,
            previousWeeklyTotalTurnOver,
            avrageWeeklyTotalTurnOver,
            currentMonthTotalTurnover,
            previousMonthTotalTurnover,
            averageMonthTotalTurnover
          );
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function currentWeekTrunover(orders, data) {
      // Set Monday as the start of the week
      if (orders.length === 0) {
        return 0;
      }

      const startOfWeek = moment()
        .startOf("week")
        .format("YYYY-MM-DD 00:00:00");
      const endOfWeek = moment().endOf("week").format("YYYY-MM-DD 23:59:59");

      // Filter orders within the current week based on reg_date
      const weeklyOrders = orders.filter((order) => {
        const regDate = moment(order.reg_date);
        return regDate.isBetween(startOfWeek, endOfWeek, null, "[]");
      });

      // Calculate the total price of the weekly orders
      const totalPrice = weeklyOrders.reduce((sum, order) => {
        return (
          sum +
          parseFloat(order.price_ex_vat_total) +
          parseFloat(order.vat_total)
        );
      }, 0);

      if (isIncludingTableTurnOver == 1) {
        return totalPrice + data.turnover_from_prodcut_table;
      } else {
        return totalPrice;
      }
    }

    function previousWeekTurnover(orders, data) {
      // Get the current date
      if (orders.length === 0) {
        return 0;
      }

      // Calculate the current week's start date (Sunday) in UTC
      const currentWeekStartDate = moment().startOf("week").utcOffset(0);

      // Calculate the start date of the previous week by subtracting a week from the current week's start date
      const previousWeekStartDate = currentWeekStartDate
        .clone()
        .subtract(1, "week")
        .format("YYYY-MM-DD 00:00:00");

      // Calculate the end date of the previous week
      const previousWeekEndDate = currentWeekStartDate
        .clone()
        .subtract(1, "second")
        .format("YYYY-MM-DD 23:59:59");

      // Filter orders within the previous week based on reg_date
      const previousWeeklyOrders = orders.filter((order) => {
        const regDate = moment(order.reg_date);
        return regDate.isBetween(
          previousWeekStartDate,
          previousWeekEndDate,
          null,
          "[]"
        );
      });

      // Calculate the total price of the orders in the previous week
      const totalPrice = previousWeeklyOrders.reduce((sum, order) => {
        return (
          sum +
          parseFloat(order.price_ex_vat_total) +
          parseFloat(order.vat_total)
        );
      }, 0);

      if (isIncludingTableTurnOver == 1) {
        return totalPrice + data.turnover_from_prodcut_table;
      } else {
        return totalPrice;
      }
    }

    function averageWeeklyTurnover(orders, data) {
      if (orders.length === 0) {
        return 0;
      }
      // Calculate the current week's start date (Sunday) in UTC
      const currentWeekStartDate = moment().startOf("week").utcOffset(0);

      // Calculate the start date of the previous week by subtracting a week from the current week's start date
      const previousWeekStartDate = currentWeekStartDate
        .clone()
        .subtract(52, "week")
        .format("YYYY-MM-DD 00:00:00");

      // Calculate the end date of the previous week
      const endOfWeek = moment().endOf("week").format("YYYY-MM-DD 23:59:59");

      // Filter orders within the previous week based on reg_date
      const previousWeeklyOrders = orders.filter((order) => {
        const regDate = moment(order.reg_date);
        return regDate.isBetween(previousWeekStartDate, endOfWeek, null, "[]");
      });
      const totalPrice = previousWeeklyOrders.reduce((sum, order) => {
        return (
          sum +
          parseFloat(order.price_ex_vat_total) +
          parseFloat(order.vat_total)
        );
      }, 0);

      const earliestOrderDate = moment(data.organizer_reg_date);

      const formattedEarliestDate = moment(earliestOrderDate);
      const currentWeek = moment().week();
      const earliestWeek = formattedEarliestDate.week();
      const earliestYear = formattedEarliestDate.year();
      const currentYear = moment().year();
      if (currentYear === earliestYear && currentWeek >= earliestWeek) {
        // Same year
        weeks = currentWeek - earliestWeek + 1;
      } else {
        weeks = currentWeek + (52 - earliestWeek) + 1;
      }
      // Ensure weeks do not exceed 52
      weeks = Math.min(weeks, 52);
      let averageWeeklyTurnover = isIncludingTableTurnOver
        ? (totalPrice + data.turnover_from_prodcut_table) / weeks
        : totalPrice / weeks;
      return averageWeeklyTurnover;
    }

    //monthly turnover
    function currentMonthTurnover(orders, data) {
      if (orders.length === 0) {
        return 0;
      }
      // Set Monday as the start of the week
      const startOfMoth = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      const endOfmonth = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
      // Filter orders within the current week based on reg_date
      const monthlyOrders = orders.filter((order) => {
        const regDate = moment(order.reg_date);
        return regDate.isBetween(startOfMoth, endOfmonth, null, "[]");
      });

      // Calculate the total price of the weekly orders
      const totalPrice = monthlyOrders.reduce((sum, order) => {
        return (
          sum +
          parseFloat(order.price_ex_vat_total) +
          parseFloat(order.vat_total)
        );
      }, 0);

      if (isIncludingTableTurnOver == 1) {
        return totalPrice + data.turnover_from_prodcut_table;
      } else {
        return totalPrice;
      }
    }

    function previousMonthTurnover(orders, data) {
      // Get the current
      if (orders.length === 0) {
        return 0;
      }

      // Calculate the current week's start date (Sunday) in UTC
      const currentMonthStartDate = moment().startOf("month").utcOffset(0);

      // Calculate the start date of the previous week by subtracting a week from the current week's start date
      const previousMonthStartDate = currentMonthStartDate
        .clone()
        .subtract(1, "month")
        .format("YYYY-MM-DD 00:00:00");

      // Calculate the end date of the previous week
      const previousMonthEndDate = currentMonthStartDate
        .clone()
        .subtract(1, "second")
        .format("YYYY-MM-DD 23:59:59");

      // Filter orders within the previous week based on reg_date
      const previousMonthOrders = orders.filter((order) => {
        const regDate = moment(order.reg_date);
        return regDate.isBetween(
          previousMonthStartDate,
          previousMonthEndDate,
          null,
          "[]"
        );
      });

      // Calculate the total price of the orders in the previous week
      const totalPrice = previousMonthOrders.reduce((sum, order) => {
        return (
          sum +
          parseFloat(order.price_ex_vat_total) +
          parseFloat(order.vat_total)
        );
      }, 0);

      if (isIncludingTableTurnOver == 1) {
        return totalPrice + data.turnover_from_prodcut_table;
      } else {
        return totalPrice;
      }
    }

    function averageMonthlyTurnover(orders, data) {
      if (orders.length === 0) {
        return 0;
      }
      // Calculate the current week's start date (Sunday) in UTC
      const currentMomnthStartDate = moment().startOf("month").utcOffset(0);

      // Calculate the start date of the previous week by subtracting a week from the current week's start date
      const previousMonthStartDate = currentMomnthStartDate
        .clone()
        .subtract(12, "month")
        .format("YYYY-MM-DD 00:00:00");

      // Calculate the end date of the previous week
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD 23:59:59");

      // Filter orders within the previous week based on reg_date
      const previousWeeklyOrders = orders.filter((order) => {
        const regDate = moment(order.reg_date);
        return regDate.isBetween(
          previousMonthStartDate,
          endOfMonth,
          null,
          "[]"
        );
      });
      const totalPrice = previousWeeklyOrders.reduce((sum, order) => {
        return (
          sum +
          parseFloat(order.price_ex_vat_total) +
          parseFloat(order.vat_total)
        );
      }, 0);

      const earliestOrderDate = moment(data.organizer_reg_date);

      // Calculate the current month and the earliest month
      const currentMonth = moment();
      months =
        currentMonth.diff(earliestOrderDate, "months") + 1 < 12
          ? currentMonth.diff(earliestOrderDate, "months") + 1
          : 12;
      let averageMonthlyTurnover = isIncludingTableTurnOver
        ? (totalPrice + data.turnover_from_prodcut_table) / months
        : totalPrice / months;

      return averageMonthlyTurnover;
    }

    ajaxGetStatisticsData();
  }
});
