moment.locale("nb");
moment.tz.setDefault("Europe/Oslo");
moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the start of the week
  },
  months:
    "Januar_Februar_Mars_April_Mai_Juni_Juli_August_September_Oktober_November_Desember".split(
      "_"
    ),
  monthsShort: "Jan_Feb_Mars_Apr_Mai_Juni_Juli_Aug_Sep_Okt_Nov_Des".split("_"),
  monthsParseExact: true,
  weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
  weekdaysShort: "Søn_Man_Tir_Ons_Tor_Fre_Lør".split("_"),
  weekdaysMin: "sø_ma_ti_on_to_fr_lø".split("_"),
});
Highcharts.setOptions({
  lang: {
    weekdays: [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag",
    ],
    shortWeekdays: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
    months: [
      "Januar",
      "Februar",
      "Mars",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
  },
});

/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertHtml(message, type, title = null) {
  var titleHtml = "";

  if (title != null && title != "") {
    titleHtml = "<strong>" + title + "</strong><br><br>";
  }

  return (
    '<div class="alert alert-' +
    type +
    ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' +
    titleHtml +
    message +
    "</div>"
  );
}

$.ajaxSetup({
  headers: {
    "X-Is-Native": window.ReactNativeWebView ? "1" : "0",
  },
});

let orderPrintInterval;

function getOrderToPrint(orderId = null) {
  const device_id = localStorage.getItem("deviceID");
  $.ajax({
    type: "POST",
    url: "/api/v1/private/order_product/get_order_products_to_print",
    data: {
      order_id: orderId,
      device_id: device_id,
    },
  })
    .done(function (data) {
      if (data && data.message) {
        clearInterval(orderPrintInterval);
        return;
      }

      if (data && data.length > 0) {
        passDataToWebView(event, "print_poll_order", data);
      }
    })
    .fail(function (data) {
      if (
        data.responseJSON.redirect_url !== undefined &&
        data.responseJSON.redirect_url !== null
      ) {
        document.location.href = data.responseJSON.redirect_url;
      }
    });
}

function getlatestOrderToPrint() {
  $.ajax({
    type: "POST",
    url: "/ajax/order/get_latest_order_to_print",
    data: { locationID: 1 },
  })
    .done(function (data) {
      document.location.href = data.redirect_url;
    })
    .fail(function (data) {
      if (
        data.responseJSON.redirect_url !== undefined &&
        data.responseJSON.redirect_url !== null
      ) {
        document.location.href = data.responseJSON.redirect_url;
      }
    });
}
/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertTable(message, type, title = null) {
  var titleHtml = "";

  if (title != null && title != "") {
    titleHtml = "<strong>" + title + "</strong><br><br>";
  }

  return (
    '<tr><td colspan="3"><div class="alert alert-' +
    type +
    ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' +
    titleHtml +
    message +
    "</div></td></tr>"
  );
}

/**
 *
 * @param string prependBeforeId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function prependAlert(
  prependBeforeId,
  message,
  type,
  title = null,
  parent = null
) {
  if (parent != null && parent != "" && $(parent).length > 0) {
    $(".alert", $(parent)).remove();
  }

  $(getAlertHtml(message, type, title)).prependTo($(prependBeforeId));
}

/**
 *
 * @param string appendAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function appendAlert(
  appendAfterId,
  message,
  type,
  title = null,
  parent = null
) {
  if (parent != null && parent != "" && $(parent).length > 0) {
    $(".alert", $(parent)).remove();
  }

  $(getAlertHtml(message, type, title)).appendTo($(appendAfterId));
}

/**
 *
 * @param string insertAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function inserAfterAlertTr(
  insertAfterId,
  message,
  type,
  title = null,
  parent = null
) {
  if (parent != null && parent != "" && $(parent).length > 0) {
    $(".alert", $(parent)).remove();
  }

  $(getAlertTable(message, type, title)).insertAfter($(insertAfterId));
}

/**
 *
 */
function popupAlert() {
  if ($("#globalPopupMessage").length > 0) {
    $("#globalPopupMessage").remove();
  }

  var popup =
    '<div id="globalPopupMessage" class="bg-success text-white"><i class="material-icons">check_circle_outline</i></div>';

  $(popup).appendTo("#content");

  setTimeout(function () {
    $("#globalPopupMessage").fadeOut(500, function () {
      $("#globalPopupMessage").remove();
    });
  }, 500);
}

function formatNumber(number) {
  if (Number.isInteger(number)) {
    return number.toLocaleString("no-NO");
  } else {
    return number.toLocaleString("no-NO", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

function errorPopupAlert(message) {
  if ($("#globalPopupMessage").length > 0) {
    $("#globalPopupMessage").remove();
  }

  var popup = `
    <div id="globalPopupMessage" class="alert bg-danger alert-dismissible fade show d-flex flex-column align-items-center justify-content-center text-center text-white p-0" role="alert">
                <div class="w-100"><i class="material-icons mb-2">error_outline</i></div>
                <div class="mb-2" style="font-size:1.5rem"><strong>${message}</strong></div>
                <button type="button" class="close" id="closePopup" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
    `;

  $(popup).appendTo("#content");

  $("#closePopup").on("click", function () {
    $("#globalPopupMessage").fadeOut(500, function () {
      $(this).remove();
    });
  });

  setTimeout(function () {
    $("#globalPopupMessage").fadeOut(500, function () {
      $(this).remove();
    });
  }, 500);
}

jQuery(document).ready(function () {
  $(document).on("click", ".clickable-td", function () {
    window.location = $(this).closest("tr").data("href");
  });

  $(document).on("click", ".clickable-li", function () {
    window.location = $(this).closest("ul").data("href");
  });

  $(document).on("click", ".clickable-span", function () {
    window.location = $(this).data("href");
  });

  if ($("form.organizer-form").length) {
    $(document).on(
      "change",
      "form.organizer-form input, form.organizer-form select, form.organizer-form textarea",
      function () {
        if (!$("form.organizer-form").hasClass("has-changed")) {
          $("form.organizer-form").addClass("has-changed");
        }
      }
    );
    $(document).on("submit", "form.organizer-form", function () {
      if ($("form.organizer-form").hasClass("has-changed")) {
        $("form.organizer-form").removeClass("has-changed");
      }
    });
  }
  $(window).bind("beforeunload", function () {
    if (
      $("form.organizer-form").length &&
      $("form.organizer-form").hasClass("has-changed")
    ) {
      return "Du har ikke lagret skjema - You have unsaved changes";
    }
  });

  let p = $("body").attr("p");
  if (
    p == "organizer_event_customers" ||
    p == "organizer_location_statistic" ||
    p == "organizer_booking_reservation"
  ) {
    if ($("body").attr("employer_token") != undefined) {
      let employerToken = $("body").attr("employer_token");
      $(".employer-menu-link").each(function () {
        let linkUrl = $(this).attr("href");
        linkUrl = linkUrl + "?employer_token=" + employerToken;
        $(this).attr("href", linkUrl);
      });
    }
  }

  $(document).on("click", ".expand-btn", function () {
    let icon = "expand_more";
    if ($(this).hasClass("expand-more")) {
      $(this).removeClass("expand-more").addClass("expand-less");
      icon = "expand_less";
    } else {
      $(this).removeClass("expand-less").addClass("expand-more");
    }

    if ($(".material-icons", $(this)).length) {
      $(".material-icons", $(this)).text(icon);
    }
  });

  $('[data-toggle="popover"]').popover();
});

//blinking button
function blinkButton(target, color) {
  // Store the original background color in a CSS variable
  var originalBgColor = $(target).css("background-color");

  // Apply the dynamic color to the button's background
  $(target).css({
    "--blink-color": color, // Set the color for the blinking effect
    "--original-bg-color": originalBgColor, // Store the original background color
  });

  // Add the blinking class to start the animation
  $(target).addClass("blink");

  // Optional: Remove blink and reset background color after 5 seconds
  setTimeout(() => {
    $(target).removeClass("blink").css("background-color", originalBgColor);
  }, 5000);
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
  let re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

function createDeviceId(length) {
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function formattedValueForNumberInput(value) {
  if (typeof value === "string") {
    value = value.replace(",", ".");
    value = parseFloat(value);
  }

  if (isNaN(value)) {
    return 0;
  }

  let truncatedValue = Math.trunc(value * 100) / 100;

  return truncatedValue % 1 === 0 ? truncatedValue + 0.0 : truncatedValue;
}

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

function formattedPriceToShow(number) {
  if (!number) {
    return 0;
  }
  let parts = number.toString().split(".");
  let decimals = parts[1]
    ? parts[1].length === 1
      ? parts[1] + "0"
      : parts[1].substring(0, 2)
    : "00";
  let formatted = parts[0] + "," + decimals;
  return formatted;
}

function floor(value, decimals = 2) {
  return Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

var inactivityTimeoutId = null;

/**
 *
 * @param function callback
 * @param number inactivityTime
 */
function startOrResetFunction(callback, inactivityTime = 300000) {
  clearTimeout(inactivityTimeoutId);

  inactivityTimeoutId = setTimeout(function () {
    callback();
  }, inactivityTime);
}

function clearInactivityTimeout() {
  clearTimeout(inactivityTimeoutId);
}

/**
 * @param {string} input
 * @returns {string}
 */
function dayFormatAbbreviation(input) {
  const days = {
    "sø.": "Søn",
    "ma.": "Man",
    "ti.": "Tir",
    "on.": "Ons",
    "to.": "Tor",
    "fr.": "Fre",
    "lø.": "Lør",
  };

  if (typeof input !== "string" || input.length < 2) {
    throw new TypeError("Input must be a string with at least 2 characters");
  }

  const abbreviation = input.slice(0, 3);

  if (days.hasOwnProperty(abbreviation)) {
    return input.replace(abbreviation, days[abbreviation]);
  }

  return input;
}

/**
 * @param {string[]} query
 * @param {boolean} silent
 */
function removeQueryParams(query, silent = true) {
  const urlParams = new URLSearchParams(window.location.search);

  if (Array.isArray(query)) {
    query.forEach((param) => urlParams.delete(param));
  } else {
    urlParams.delete(query);
  }

  const newUrl =
    window.location.pathname +
    (urlParams.toString() ? `?${urlParams.toString()}` : "") +
    window.location.hash;

  const historyMethod = silent
    ? window.history.replaceState
    : window.history.pushState;
  historyMethod.call(window.history, null, "", newUrl);
}
