$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery = $("body").attr("start_date") || null;
  let endDateFromQuery = $("body").attr("end_date") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;

  if (p === "statistic_vat") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );
    handleCustomerIdChange(getVatPercent);
    handleCustomPeriodChange(getVatPercent);
    handleTopProductGroupChange(getVatPercent);
    handleSupplierIdChange(getVatPercent);
    handleProductGroupChange(getVatPercent);
    handleUserChange(getVatPercent);
    handlePlatformChange(getVatPercent);
    handlePeriodChange(getVatPercent);
    handleDateAndTimeChange(getVatPercent);
    handlePreviousButton(getVatPercent);
    handleNextButton(getVatPercent);
    handleCompareByChange(getVatPercent);
    handleProductChange(getVatPercent);

    function eventLineChart(seriesData, interval = null) {
      let categories = seriesData.map((item) => item.name); // Extract dates for x-axis categories

      // Prepare the series array
      let series = [
        {
          name: "MVA 0%",
          data: seriesData.map((item) => item.data[0][0]), // First line of Vipps
          stack: "MVA 0%",
        },
        {
          name: "MVA 12%",
          data: seriesData.map((item) => item.data[0][1]), // First line of Kort
          stack: "MVA 12%",
        },
        {
          name: "MVA 15%",
          data: seriesData.map((item) => item.data[0][2]), // First line of Terminal
          stack: "MVA 15%",
        },
        {
          name: "MVA 25%",
          data: seriesData.map((item) => item.data[0][3]), // First line of Kontanter
          stack: "MVA 25%",
        },
      ];

      // Conditionally add last week and last year data if compareBy is not null or "0"
      if (compareBy && compareBy !== "0") {
        series = [
          {
            name: "MVA 0%",
            data: seriesData.map((item) => item.data[0][0]), // First line of MVA 0%
            stack: "MVA 0%",
          },
          {
            name: "MVA 0% forrige uke",
            data: seriesData.map((item) => item.data[1][0]), // Second line of MVA 0%
            stack: "MVA 0%", // Same stack as "MVA 0%" to group the columns
          },
          {
            name: "MVA 0% forrige år",
            data: seriesData.map((item) => item.data[2][0]), // Third line of MVA 0%
            stack: "MVA 0%", // Same stack as "Vipps" to group the columns
          },
          {
            name: "MVA 12%",
            data: seriesData.map((item) => item.data[0][1]), // First line of MVA 12%
            stack: "MVA 12%",
          },
          {
            name: "MVA 12% forrige uke",
            data: seriesData.map((item) => item.data[1][1]), // Second line of MVA 12%
            stack: "MVA 12%", // Same stack as "MVA 12%" to group the columns
          },
          {
            name: "MVA 12% forrige år",
            data: seriesData.map((item) => item.data[2][1]), // Third line of MVA 12%
            stack: "MVA 12%", // Same stack as "Kort" to group the columns
          },
          {
            name: "MVA 15%",
            data: seriesData.map((item) => item.data[0][2]), // First line of MVA 15%
            stack: "MVA 15%",
          },
          {
            name: "MVA 15% forrige uke",
            data: seriesData.map((item) => item.data[1][2]), // Second line of MVA 15%
            stack: "MVA 15%", // Same stack as "MVA 15%" to group the columns
          },
          {
            name: "MVA 15% forrige år",
            data: seriesData.map((item) => item.data[2][2]), // Third line of MVA 15%
            stack: "MVA 15%", // Same stack as "MVA 15%" to group the columns
          },
          {
            name: "MVA 25%",
            data: seriesData.map((item) => item.data[0][3]), // First line of MVA 25%
            stack: "MVA 25%",
          },
          {
            name: "MVA 25% forrige uke",
            data: seriesData.map((item) => item.data[1][3]), // Second line of MVA 25%
            stack: "MVA 25%", // Same stack as "MVA 25%" to group the columns
          },
          {
            name: "MVA 25% forrige år",
            data: seriesData.map((item) => item.data[2][3]), // Third line of MVA 25%
            stack: "MVA 25%", // Same stack as "MVA 25%" to group the columns
          },
        ];
      }

      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: { type: "column" },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 2, // Adjust line width as needed
            stacking: "normal", // Enable stacking of columns in the same group
          },
        },
        xAxis: {
          categories: categories,
          labels: {
            formatter: function () {
              if (startDate.split(" ")[0] == endDate.split(" ")[0]) {
                return Highcharts.dateFormat("%H:%M", this.value);
              } else if (customPeriod === "week") {
                return "Uke" + " " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat("%B", this.value);
              } else {
                return Highcharts.dateFormat("%e / %b", this.value);
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          min: 0,
          title: {
            text: " ",
          },
        },
        series: series,
        tooltip: {
          formatter: function () {
            let tooltipText;
            let tooltipDate;
            let tooltipWeek;

            if (this.series.name.includes("uke")) {
              let date = moment(this.x);
              date.subtract(1, "week");
              tooltipDate = date.valueOf();
            } else if (this.series.name.includes("forrige")) {
              let date = moment(this.x);
              date.subtract(1, "year");
              tooltipDate = date.valueOf();
            } else {
              tooltipDate = this.x;
            }

            if (this.series.name.includes("uke")) {
              tooltipWeek = parseInt(this.x) - 1;
            } else {
              tooltipWeek = this.x;
            }

            if (startDate.split(" ")[0] == endDate.split(" ")[0]) {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                tooltipWeek +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }

    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }

    function getVatPercent(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0,
      customerId,
      compareBy = null,
      productId = 0
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_vat/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
          customerId,
          compareBy,
          productId,
        },
      })
        .done(function (data) {
          if (data) {
            $("#key-figure-mva-0").text(formattedPriceToShow(data.noVatCount));
            $("#key-figure-column-1").attr("data-content", floor(data.noVatCount, 3).toString().replace(".", ","));
            $("#key-figure-mva-12").text(
              formattedPriceToShow(data.reduceVatCount)
            );
            $("#key-figure-column-2").attr("data-content", floor(data.reduceVatCount, 3).toString().replace(".", ","));
            $("#key-figure-mva-15").text(
              formattedPriceToShow(data.foodVatCount)
            );
            $("#key-figure-column-3").attr("data-content", floor(data.foodVatCount, 3).toString().replace(".", ","));
            $("#key-figure-mva-25").text(
              formattedPriceToShow(data.regularVatCount)
            );
            $("#key-figure-column-4").attr("data-content", floor(data.regularVatCount, 3).toString().replace(".", ","));
          } else {
            $("#key-figure-mva-0").text("0");
            $("#key-figure-mva-12").text("0");
            $("#key-figure-mva-15").text("0");
            $("#key-figure-mva-25").text("0");
          }

          if (data.data) {
            eventLineChart(data.data);
          }

          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getMonthGraph(data) {
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let monthNumbers = [];

      while (startDay.isBefore(endDay)) {
        let monthNumber = startDay.month() + 1;
        monthNumbers.push(monthNumber);
        startDay.add(1, "month");
      }
      let seriesData = [];
      $.map(monthNumbers, function (month_number) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === month_number.toString();
        });
        let monthNumberToDate =
          moment()
            .startOf("year")
            .add(month_number - 1, "month")
            .format("YYYY-MM-DD") + " 00:00";
        let monthData1 = [0, 0, 0, 0];
        let monthData2 = [0, 0, 0, 0];
        let monthData3 = [0, 0, 0, 0];
        if (found && found.length > 0) {
          $.map(found, function (item) {
            monthData1[0] += item.payment_data[0];
            monthData1[1] += item.payment_data[1];
            monthData1[2] += item.payment_data[2];
            monthData1[3] += item.payment_data[3];
          });
        }

        let formatedDate = new Date(monthNumberToDate);
        let timestamp = formatedDate.getTime();
        seriesData.push({
          name: timestamp,
          data: [monthData1, monthData2, monthData3],
        });
      });
      eventLineChart(seriesData);
    }

    getVatPercent(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy,
      productId
    );
    toggleCustomDatePicker();
  }
});
