$(document).ready(function () {
  let p = $("body").attr("p");
  $(document).on("click", ".print-last-order", function (e) {
    e.preventDefault();
    getlatestOrderToPrint();
  });

  $(document).on(
    "hidden.bs.modal",
    "#cancel-last-order-confirm-model",
    function () {
      $(this).remove();
      $(".modal-backdrop").remove();
    }
  );

  $(document).on("click", "#open-cancel-last-order-model", function (e) {
    e.preventDefault();
    const locationId = $(this).attr("data-location-id");
    const orderId = $(this).attr("data-order-id") ?? null;
    $.ajax({
      type: "POST",
      url: "/api/v1/private/order/get-cancel-last-order-model",
      data: {
        location_id: locationId,
        order_id: orderId,
      },
    })
      .done(function (data) {
        if (data && data.html) {
          $("body").append(data.html);
          $("#cancel-last-order-confirm-model").modal("show");
        }

        if (data && data.error) {
          return alert(data.error);
        }
      })
      .fail(function (data) {
        if (data && data.errors) {
          return alert(data.errors);
        }
        return alert(data.error);
      });
  });

  $(document).on("click", "#cancel-last-order", function (e) {
    e.preventDefault();
    const orderId = $(this).attr("data-order-id");
    const locationId = $(this).attr("data-location-id");
    $.ajax({
      type: "POST",
      url: "/api/v1/private/order/cancel-last-order",
      data: {
        order_id: orderId,
        location_id: locationId,
      },
    })
      .done(function (data) {
        if (data && data.success) {
          $("#model-body-message").hide();
          $("#last-order-delete-cancel").hide();
          $("#cancel-last-order").hide();
          $("#cancel-last-order-information").empty();
          $("#cancel-last-order-information").html(`
            <div class="d-flex flex-column align-items-center">
                <div>
                    <i class="material-icons" style="font-size: 3rem; color: green;">check_circle_outline</i>
                </div>
                <div style="color: green; font-size: 1.2rem;">${data.success}</div>
            </div>
            `);

          setTimeout(() => {
            $("#cancel-last-order-confirm-model").modal("hide");
            $(`#order-product-order-id-${orderId}`).fadeOut(500);
            const $menuLinks = $("#menuLinks");
            if ($menuLinks.hasClass("show")) {
              $menuLinks.collapse("hide");
            }
          }, 2000);
        }

        if (data && data.error) {
          $("#model-body-message").hide();
          $("#last-order-delete-cancel").hide();
          $("#cancel-last-order").hide();
          $("#cancel-last-order-information").empty();
          $("#cancel-last-order-information").html(`
            <div class="d-flex flex-column align-items-center">
                <div>
                    <i class="material-icons" style="font-size: 3rem; color: red;">error_outline</i>
                </div>
                <div style="color: red; font-size: 1.2rem; text-align: center;">${data.error}</div>
            </div>
            `);

          setTimeout(() => {
            $("#cancel-last-order-confirm-model").modal("hide");
          }, 5000);
        }
      })
      .fail(function (data) {
        if (data && data.responseJSON.error) {
          $("#model-body-message").hide();
          $("#last-order-delete-cancel").hide();
          $("#cancel-last-order").hide();
          $("#cancel-last-order-information").empty();
          $("#cancel-last-order-information").html(`
            <div class="d-flex flex-column align-items-center">
                <div>
                    <i class="material-icons" style="font-size: 3rem; color: red;">error_outline</i>
                </div>
                <div style="color: red; font-size: 1.2rem; text-align: center;">${data.responseJSON.error}</div>
            </div>
            `);

          setTimeout(() => {
            $("#cancel-last-order-confirm-model").modal("hide");
          }, 5000);
        }
      });
  });
  if (
    p == "organizer_events" ||
    p == "organizer_settings" ||
    p == "organizer_settings_users" ||
    p == "organizer_settings_term" ||
    p == "organizer_events" ||
    p == "organizer_event" ||
    p == "organizer_payout_reports" ||
    p == "organizer_event_sales" ||
    p == "organizer_event_sales_event_ticket_category" ||
    p == "organizer_event_subevent_sales" ||
    p == "organizer_event_subevent_sales_event_ticket_category" ||
    p == "organizer_event_subevent_customers" ||
    p == "organizer_event_subevent_entered_customers" ||
    p == "organizer_event_subevent" ||
    p == "organizer_event_ticket_category" ||
    p == "organizer_event_customers" ||
    p == "organizer_event_entered_customers" ||
    p == "organizer_event_ticket_category" ||
    p == "visit_tracker_location" ||
    p == "visit_tracker_visitor" ||
    p == "events"
  ) {
    $("#organizerAdminMenuLeftToggleButton").click(function () {
      if ($(this).text() == "menu") {
        $(this).text("close");
        removeLeftMenuCollapseCookie();
        openLeftMenu($(this));
      } else {
        $(this).text("menu");
        addLeftMenuCollapseCookie();
        closeLeftMenu($(this));
      }
    });

    function closeLeftMenu(button) {
      $("#admin-content").addClass("collapsed");
      button.removeClass("ml-2");
      button.closest("nav.sidebar").removeClass("col-2");
      button.closest(".sidebar-sticky").find("ul").addClass("d-none");
      $("div#admin-content-frame").removeClass("col-md-10");
      $("div#admin-content-frame").addClass("col-md-12");
    }

    function openLeftMenu(button) {
      $("#admin-content").removeClass("collapsed");
      button.addClass("ml-2");
      button.closest("nav.sidebar").addClass("col-2");
      button.closest(".sidebar-sticky").find("ul").removeClass("d-none");
      $("div#admin-content-frame").removeClass("col-md-12");
      $("div#admin-content-frame").addClass("col-md-10");
    }

    function removeLeftMenuCollapseCookie() {
      let daysForCookieToExpire = 0;
      let cookieExpires = new Date(
        new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24
      );
      document.cookie =
        "collapse_left_menu = ;expires=" + cookieExpires + "; path=/";
    }

    function addLeftMenuCollapseCookie() {
      let daysForCookieToExpire = 1;
      let cookieExpires = new Date(
        new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24
      );
      document.cookie =
        "collapse_left_menu = true;expires=" + cookieExpires + "; path=/";
    }
  }
});
